import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'

const Measurement = {
  id: null,
  name: '',
  full_name: '',
  code: '',
}

const MeasurementResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/measurement/list/',
  },
  details: {
    method: 'GET',
    url: 'warehouse/measurement/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'warehouse/measurement/create/',
  },
  update: {
    method: 'PUT',
    url: 'warehouse/measurement/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'warehouse/measurement/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    data: null,

    fetching: false,
    model: ut.deepClone(Measurement),
    saving: false,
    deleting: {},
  },

  getters: {
    getMeasurement: (state) => (id) => state.data?.results?.find(m => m.id === id),
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(Measurement), ...data}
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = MeasurementResource.list(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit}, id) {
      if (!state.fetching) {
        commit('setModel', {})
        state.fetching = MeasurementResource.details({id})
          .then(({body}) => {
            commit('setModel', body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    save({state, commit}) {
      state.saving = true
      let promise
      if (state.model.id) {
        promise = MeasurementResource.update({id: state.model.id}, state.model)
      } else {
        promise = MeasurementResource.create(state.model)
      }

      return promise
        .then(({body}) => {
          commit('setModel', body)
        })
        .finally(() => state.saving = false)
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return MeasurementResource.remove({id})
        .then(() => dispatch('fetch'))
        .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
