import Vue from 'vue'
import ut from '@/utils'

import moment from 'moment'
import {SERVER_DATE_FORMAT} from '@/filters'

import {Resource} from 'vue-resource'

const SalaryStatement = {
  id: null,
  is_closed: false,
  date: '',
  year: (new Date).getFullYear(),
  month: (new Date).getMonth() + 1,
  rows: [{staffsalarydate_set: []}],
}

const DailyJobVolume = {
  id: null,
  reg_date: '',
  total: 0,
}

const SalaryStatementResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'staff/salary-month-statement/list/',
  },
  details: {
    method: 'GET',
    url: 'staff/salary-month-statement/detail/{id}/',
  },
  toggleState: {
    method: 'PUT',
    url: 'staff/salary-month-statement/close/{id}/',
  },
  create: {
    method: 'POST',
    url: 'staff/salary-month-statement/create/',
  },
  update: {
    method: 'PUT',
    url: 'staff/staffsalarydate/list-update/',
  },
  remove: {
    method: 'DELETE',
    url: 'staff/salary-month-statement/delete/{id}/',
  },
  availableDates: {
    method: 'GET',
    url: 'staff/salary-statement-rows/{id}/',
  },
})

const DailyJobVolumeResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/product/job-volume-day/list/',
  },
  details: {
    method: 'GET',
    url: 'warehouse/product/job-volume-day/detail/{id}/',
  },
  update: {
    method: 'PUT',
    url: 'staff/salary-volume-create/',
  },
})

const StartBalanceResource = new Resource('/', {}, {
  update: {
    method: 'PUT',
    url: 'staff/salary-month-statement/row-remain/update/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    data: null,

    fetching: false,
    model: ut.deepClone(SalaryStatement),

    saving: false,
    deleting: {},

    fetchingVolumes: false,
    savingVolumes: false,
    volumes: null,

    fetchingSalaryAvailableMonths: false,
    salaryAvailableMonths: [],
  },

  getters: {
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setVolumes(state, data) {
      state.volumes = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(SalaryStatement), ...data}
    },

    setAvailableMonths(state, data = []) {
      data.sort((a, b) => a.month < b.month ? -1 : 1)
      state.salaryAvailableMonths = data
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = SalaryStatementResource
                            .list(ut.objFilter(filters))
                            .then((response) => {
                              commit('setItems', response.body)
                            })
                            .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit, dispatch}, data) {
      if (!state.fetching) {
        commit('setModel', {})
        state.fetching = SalaryStatementResource.details(data)
          .then(({body}) => {
            commit('setModel', body)
            dispatch('fetchVolumes', {
              reg_date_after: data.date_from ? data.date_from : moment(body.date).startOf('month').format(SERVER_DATE_FORMAT),
              reg_date_before: data.date_to ? data.date_to : moment(body.date).endOf('month').format(SERVER_DATE_FORMAT),
            })
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    create({state, commit}) {
      state.saving = true

      return SalaryStatementResource.create(ut.objFilter(state.model))
                  .then(({body}) => commit('setModel', {}))
                  .catch(({body}) => Vue.$eventBus.$emit('notification:error', (body.non_field_errors || []).join(', ')))
                  .finally(() => state.saving = false)
    },

    update({state, commit}, payload) {
      state.saving = true
      return SalaryStatementResource
                .update(payload)
                .then(({body}) => {
                  commit('setModel', {})
                  Vue.$eventBus.$emit('notification:success', 'Изменения сохранены')
                })
                .finally(() => state.saving = false)
    },

    toggleState({state, commit}, id) {
      state.saving = true
      return SalaryStatementResource
                .toggleState({id}, {is_closed: true})
                .finally(() => state.saving = false)
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return SalaryStatementResource
                .remove({id})
                .then(() => dispatch('fetch'))
                .finally(() => Vue.set(state.deleting, id, false))
    },

    fetchVolumes({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetchingVolumes) {
        commit('setVolumes', null)
        state.fetching = DailyJobVolumeResource
                            .list(ut.objFilter(filters))
                            .then((response) => {
                              commit('setVolumes', response.body)
                            })
                            .finally(() => state.fetchingVolumes = false)
      }

      return state.fetchingVolumes
    },

    updateVolumes({state, commit, dispatch}, data) {
      if (!state.savingVolumes) {
        state.savingVolumes = DailyJobVolumeResource
                                .update(ut.objFilter(data))
                                .then(({body}) => {
                                  Vue.$eventBus.$emit('notification:success', 'Изменения сохранены')
                                })
                                .finally(() => state.savingVolumes = false)
      }

      return state.savingVolumes
    },

    setStartBalance({state, commit, dispatch}, data) {
        return StartBalanceResource
                    .update({id: data.id}, ut.objFilter(data))
    },

    getAvailableDates({state, commit, dispatch}, {id, year}) {
      if (!state.fetchingSalaryAvailableMonths) {
        commit('setAvailableMonths', [])
        state.fetchingSalaryAvailableMonths = SalaryStatementResource.availableDates({id, year})
                        .then(({body}) => {
                          commit('setAvailableMonths', body.results)
                        })
                        .finally(() => state.fetchingSalaryAvailableMonths = false)
      }

      return state.fetchingSalaryAvailableMonths
    }
  },
}
