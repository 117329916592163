<template>
  <v-tooltip left>
    <template #activator="{on, attrs}">
      <v-icon :left="left" small v-on="on" :color="getStaffWorkType(type).color">{{getStaffWorkType(type).icon}}</v-icon>
      <span v-if="withTitle">{{getStaffWorkType(type).title}}</span>
    </template>
    <span>{{getStaffWorkType(type).title}}</span>
  </v-tooltip>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'staff-work-type',
  props: {
    type: {required: true},
    left: Boolean,
    withTitle: Boolean,
  },

  computed: {
    ...mapGetters('Staff', ['getStaffWorkType']),
  }
}
</script>
