<template>
  <v-row dense no-gutters align="center" justify="center" class="has-top-border pt-2">
    <v-col cols="1">
      <v-select :items="pageSizes" v-model="perPage"
                hide-details label="Кол-во элементов" dense
      ></v-select>
    </v-col>
    <v-col cols="10">
      <v-pagination v-model="localPage" :total-visible="10" :length="totalPages" :disabled="totalPages === 1"></v-pagination>
    </v-col>
    <v-col cols="1" class="text-right">
      <slot name="right"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'pagination',

  props: ['limit', 'page', 'totalPages'],

  data() {
    return {
      pageSizes: [
        {id: 10, text: 10},
        {id: 20, text: 20},
        {id: 30, text: 30},
        {id: 40, text: 40},
        {id: 50, text: 50},
      ]
    }
  },

  computed: {
    perPage: {
      get() {
        return this.limit
      },

      set(val) {
        this.$emit('update:limit', val)
      },
    },

    localPage: {
      get() {
        return this.page
      },

      set(val) {
        this.$emit('update:page', val)
      },
    }
  },
}
</script>

<style>
  .has-top-border {
    border-top: 1px solid #eee;
  }
</style>
