<template>
  <v-card :loading="!!fetching">
    <v-card-title>
      Единица измерения #{{model.id}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div>Наименование: {{model.name}}</div>
      <div>Название: {{model.full_name}}</div>
      <div>Код: {{model.code}}</div>
    </v-card-text>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState} = createNamespacedHelpers('Measurement')

  export default {
    name: 'measurement-details',

    computed: {
      ...mapState(['fetching', 'model']),
    },
  }
</script>
