import Vue from 'vue'
import ut from '@/utils'
import {SERVER_DATE_FORMAT} from '@/filters'
import moment from 'moment'
import {Currencies} from './currency-rates'

import {Resource} from 'vue-resource'

const Transfer = {
  id: null,
  reg_number: '',
  reg_date: moment().format(SERVER_DATE_FORMAT),
  counteragent: '',
  contract: '',
  income_number: '',
  warehouse: '',
  currency: Currencies[0].id,
  priyomka_elements: [],
}

export const TransferItem = {
  id: null,
  raw_material: null,
  amount: 1,
  price: 0,
  nds_percent: 0,
  discount_percent: 0,
  total: 0,
  priyomka_document: null,
}

const TransferResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/priyomkadoc/list/',
  },
  details: {
    method: 'GET',
    url: 'warehouse/priyomkadoc/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'warehouse/priyomkadoc/create/',
  },
  update: {
    method: 'PUT',
    url: 'warehouse/priyomkadoc/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'warehouse/priyomkadoc/delete/{id}/',
  },
})

const TransferItemResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/priyomkaelement/list/',
  },
  details: {
    method: 'GET',
    url: 'warehouse/priyomkaelement/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'warehouse/priyomkaelement/create/',
  },
  update: {
    method: 'PUT',
    url: 'warehouse/priyomkaelement/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'warehouse/priyomkaelement/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    data: null,

    fetching: false,
    fetchingDetails: false,
    model: ut.deepClone(Transfer),
    transferItems: [],

    saving: false,
    deleting: {}
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    updateModel(state, data) {
      state.model = {...state.model, ...data}
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(Transfer), ...data}

      let counterAgentId
      if (state.model.contract && ('object' === typeof state.model.contract)) {
        state.model.contract_reg_number = state.model.contract.reg_number
        state.model.contract_code = state.model.contract.code
        counterAgentId = state.model.contract.counteragent.id
        state.model.contract = state.model.contract.id
      }

      if (state.model.counteragent && ('object' === typeof state.model.counteragent)) {
        state.model.counteragent_full_name = state.model.counteragent.full_name
        state.model.counteragent_inn = state.model.counteragent.inn
        state.model.counteragent = state.model.counteragent.id || counterAgentId
      }

      if (state.model.warehouse && ('object' === typeof state.model.warehouse)) {
        state.model.warehouse_name = state.model.warehouse.name
        state.model.warehouse = state.model.warehouse.id
      }
    },

    setTransferItems(state, items) {
      state.transferItems = items
      state.transferItems.map(el => {
        if (el.raw_material && ('object' === typeof el.raw_material)) {
          el.raw_material_name = el.raw_material.name
          el.raw_material_measurement = el.raw_material.measurement
          el.raw_material = el.raw_material.id
        }
      })
    },

    createItem(state, data = {}) {
      state.transferItems.push({...ut.deepClone(TransferItem), ...data})
    },
    setItem(state, {index, item}) {
      Vue.set(state.transferItems, index, item)
    },
    clearItem(state, index) {
      state.transferItems.splice(index,1)
    }
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = TransferResource.list(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit}, id) {
      if (!state.fetchingDetails) {
        commit('setModel', {})
        state.fetchingDetails = TransferResource.details({id})
          .then(({body}) => {
            commit('setModel', body)
            commit('setTransferItems', body.priyomka_elements)
          })
          .finally(() => state.fetchingDetails = false)
      }

      return state.fetchingDetails
    },

    save({state, commit}) {
      state.saving = true

      let promise
      if (state.model.id) {
        promise = TransferResource.update({id: state.model.id}, state.model)
      } else {
        state.model.priyomka_elements = state.transferItems.map(ri => ut.objFilter(ri))
        promise = TransferResource.create(state.model)
      }

      return new Promise((resolve, reject) => {
        promise.then(() => {
          if (state.model.id) {
            let promises = []
            state.transferItems.forEach(ri => {
              if (ri.id) {
                promises.push(TransferItemResource.update({id: ri.id}, ut.objFilter(ri)))
              } else {
                promises.push(TransferItemResource.create({...ut.objFilter(ri), priyomka_document: state.model.id}))
              }
            })

            Promise.all(promises)
              .then(() => {
                resolve()
                commit('setModel', {})
                commit('setTransferItems', [])
              })
              .catch(reject)
              .finally(() => state.saving = false)
          } else {
            resolve()
            commit('setModel', {})
            commit('setTransferItems', [])
            state.saving = false
          }
        })
        .catch(reject)
        .finally(() => state.saving = false)
      })
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return TransferResource.remove({id})
        .then(() => dispatch('fetch'))
        .finally(() => Vue.set(state.deleting, id, false))
    },

    removeItem({state, dispatch}, index) {
      if (state.transferItems[index].id) {
        Vue.set(state.deleting, index, true)
        return TransferItemResource.remove({id: state.transferItems[index].id})
          .then(() => {
            state.transferItems.splice(index, 1)
          })
          .finally(() => Vue.set(state.deleting, index, false))
      } else {
        state.transferItems.splice(index, 1)
      }
    },
  },
}
