<template>
    <v-layout align-center justify-center>
        <v-flex shrink class="text-center">
            <h1 class="title mt-10">Ошибка!</h1>
            <p>Ничего не найдено</p>
        </v-flex>
    </v-layout>
</template>

<script>
  export default {
    name: 'not-found',
  }
</script>
