import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'

const StaffWorkTypes = [
  {id: 'daily', title: 'График', color: 'success', icon: 'mdi-timetable'},
  {id: 'volume', title: 'Объемный', color: 'info', icon: 'mdi-hammer-wrench'},
]

const Staff = {
  id: null,
  first_name: '',
  last_name: '',
  middle_name: '',
  passport: '',
  description: '',
  phone: '',
  salary_rate: 0,
  type: StaffWorkTypes[0].id,
  is_active: true,
}

const StaffResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'staff/list/',
  },
  details: {
    method: 'GET',
    url: 'staff/detail/{id}/',
  },
  history: {
    method: 'GET',
    url: 'staff/salary/list/',
  },
  create: {
    method: 'POST',
    url: 'staff/create/',
  },
  update: {
    method: 'PUT',
    url: 'staff/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'staff/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    StaffWorkTypes,
    data: null,

    fetching: false,
    fetchingDetails: false,
    model: ut.deepClone(Staff),

    saving: false,
    deleting: {},

    fetchingHistory: false,
    history: null,
  },

  getters: {
    getStaff: (state) => (id) => state.data?.results?.find(m => m.id === id),
    getStaffWorkType: (state) => (id) => StaffWorkTypes.find(m => m.id === id),
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(Staff), ...data}
    },

    setHistory(state, data) {
      state.history = data
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = StaffResource
                            .list(ut.objFilter(filters))
                            .then((response) => {
                              commit('setItems', response.body)
                            })
                            .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit}, id) {
      if (!state.fetchingDetails) {
        commit('setModel', {})
        state.fetchingDetails = StaffResource.details({id})
          .then(({body}) => {
            commit('setModel', body)
          })
          .finally(() => state.fetchingDetails = false)
      }

      return state.fetchingDetails
    },

    fetchHistory({state, commit}, filters = {}) {
      if (!state.fetchingHistory) {
        commit('setHistory', null)
        state.fetchingHistory = StaffResource
          .history(ut.objFilter(filters))
          .then((response) => {
            commit('setHistory', response.body)
          })
          .finally(() => state.fetchingHistory = false)
      }

      return state.fetchingHistory
    },

    save({state, commit}) {
      state.saving = true
      let promise
      if (state.model.id) {
        promise = StaffResource.update({id: state.model.id}, state.model)
      } else {
        promise = StaffResource.create(state.model)
      }

      return promise
        .then(({body}) => {
          commit('setModel', body)
        })
        .finally(() => state.saving = false)
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return StaffResource.remove({id})
        .then(() => dispatch('fetch'))
        .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
