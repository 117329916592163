<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <template>
        <v-col cols="12" class="pb-0">
          <span style="vertical-align:middle;" class="text-h5">
            <v-icon color="black">mdi-script-outline</v-icon> Список универсальных расходов
          </span>
          <template v-if="userService.can('universal_expenditure_add_button')">
            <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
                   @click="showForm()"
            >
              <v-icon left>mdi-plus</v-icon> Добавить
            </v-btn>
            <v-dialog v-model="formDialog" width="70vw" persistent>
              <universal-expense-form v-if="formDialog"
                                      @universal-expense:form:saved="closeForm"
                                      @universal-expense:form:cancel="closeForm"
              ></universal-expense-form>
            </v-dialog>
          </template>
        </v-col>

        <v-col cols="2">
          <v-menu v-model="fromDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="fromDateFormatted"
                            label="с" prepend-inner-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on" hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.reg_date_after" :disabled="!!fetching"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-menu v-model="toDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="toDateFormatted"
                            label="по" prepend-inner-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on" hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.reg_date_before" :disabled="!!fetching"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-autocomplete v-model="filter.counteragent" :items="counteragents"
                          item-value="id" item-text="full_name"
                          prepend-inner-icon="mdi-account-group" label="Контрагент"
                          :loading="!!counteragentsLoading" :disabled="!!counteragentsLoading"
                          clearable hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete v-model="filter.type" :items="UniversalExpenseTypes"
                          item-value="id" item-text="label"
                          prepend-inner-icon="mdi-script-outline" label="Тип расхода"
                          clearable hide-details
          ></v-autocomplete>
        </v-col>
      </template>
    </v-row>

    <v-data-table
      :headers="nonSortableHeaders"
      :items="universalExpenses"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.reg_date|date}}</td>
          <td>{{(getCounteragent(item.counteragent) || {}).full_name}}</td>
          <td>{{getType(item.type).label}}</td>
          <td class="text-right">{{item.amount|money(null, true, false)}}</td>
          <td class="text-right">{{item.description|shorten(40)}}</td>
          <td class="text-right">
              <v-btn class="ml-2" icon small depressed color="success" @click="showDetails(item)">
                <v-icon small>mdi-eye</v-icon>
              </v-btn>

              <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
                <v-icon small>mdi-pen</v-icon>
              </v-btn>
              <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item, removeEventName)"
                     :loading="deleting[item.id]"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-if="detailDialog" v-model="detailDialog" width="70vw">
      <universal-expense-details @dialog:close="closeDetails"></universal-expense-details>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import {mapState as defaultMapState, mapGetters as defaultMapGetters, createNamespacedHelpers} from 'vuex'
  const {mapState, mapGetters, mapActions, mapMutations} = createNamespacedHelpers('UniversalExpense')

  import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'
  import {hasFilter, hasDialog} from '@/mixins'

  import UniversalExpenseForm from '@/modules/universal-expense/form'
  import UniversalExpenseDetails from '@/modules/universal-expense/details'

  export default {
    name: 'universal-expense-list',

    mixins: [hasFilter, hasDialog],

    customFilters: {
      reg_date_after: moment().subtract(1, 'month').format(SERVER_DATE_FORMAT),
      reg_date_before: moment().format(SERVER_DATE_FORMAT),
      counteragent: null,
      type: null,
    },

    data() {
      return {
        removeEventName: 'universal-expense:removed',
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'UniversalExpenseTypes',
        'fetching',
        'data',
        'deleting',
      ]),
      ...mapGetters(['getType']),

      headers() {
        return [
          {text: '#', align: 'center', width: '75'},
          {text: 'Дата'},
          {text: 'Контрагент'},
          {text: 'Тип'},
          {text: 'Сумма, сум', align: 'right'},
          {text: 'Комментарий', align: 'right'},
          {text: 'Действия', align: 'right', sortable: false, width: '150'},
        ]
      },

      universalExpenses() {
        return this.data?.results || []
      },

      ...defaultMapState('Counteragent', {
        counteragents: state => (state.data?.results || []),
        counteragentsLoading: state => !!state.fetching,
      }),
      ...defaultMapGetters('Counteragent', ['getCounteragent']),

      toDateFormatted() {
        return moment(this.filter.reg_date_before).format(USER_DATE_FORMAT)
      },

      fromDateFormatted() {
        return moment(this.filter.reg_date_after).format(USER_DATE_FORMAT)
      },
    },

    methods: {
      ...mapActions(['fetch', 'remove']),
      ...mapMutations(['setModel']),

      showDetails(item) {
        this.setModel(item)
        this.detailDialog = true
      },

      showForm(item = {}) {
        this.setModel(item)
        this.formDialog = true
      },
    },

    mounted() {
      this.$eventBus.$on(this.removeEventName, () => this.fetch(this.filter))
      this.$store.dispatch('Counteragent/fetch')
      this.$eventBus.$emit('setTitle', 'Список универсальных расходов')
    },

    beforeDestroy() {
      this.$eventBus.$off(this.removeEventName)
    },

    components: {
      UniversalExpenseForm,
      UniversalExpenseDetails,
    }
  }
</script>
