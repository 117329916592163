import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'
import {UniversalExpenseTypes} from './universal-expense'

const SourceTypes = [
  ...UniversalExpenseTypes,
  {id: 'universal', label: 'универсальные расходы'},
]

const ReportResource = new Resource('/', {}, {
  materialReport: {
    method: 'GET',
    url: 'reports/warehouse/raw_materials/remains/',
  },
  productReport: {
    method: 'GET',
    url: 'reports/warehouse/product/remains/',
  },
  productCollectionReport: {
    method: 'GET',
    url: 'reports/warehouse/producted/products/',
  },
  intermediateProduction: {
    method: 'GET',
    url: 'reports/warehouse/productdoc-remains/in-period/',
  },
  intermediateConsignment: {
    method: 'GET',
    url: 'reports/warehouse/consignmentdoc-remains/in-period/',
  },
  loaners: {
    method: 'GET',
    url: 'reports/loaners/',
  },
  operations: {
    method: 'GET',
    url: 'reports/financial_operations/list/',
  },
  productsByMonths: {
    method: 'GET',
    url: 'reports/warehouse/producted/products/by-months/',
  },
  consignmentsByMonths: {
    method: 'GET',
    url: 'reports/warehouse/consigned/products/by-months/',
  },
  turnoverByMonths: {
    method: 'GET',
    url: 'reports/financial_operations/by-months/',
  },
  turnoverByDays: {
    method: 'GET',
    url: 'reports/financial_operations/by-days/',
  },
  turnoverTotals: {
    method: 'GET',
    url: 'reports/financial_operations/year-month-week-day/',
  },
  expendituresBySource: {
    method: 'GET',
    url: 'reports/financial_operations/expenditures-by-source/',
  },
  details: {
    method: 'GET',
    url: 'reports/financial_operations/detail/{id}/',
  },
  producement: {
    method: 'GET',
    url: 'reports/warehouse/producttype-remains/by-days/',
  },
})

export default {
  namespaced: true,

  state: {
    SourceTypes,

    data: null,
    fetching: false,

    dashboardData: {},
    fetchingDashboard: {},
  },

  getters: {
    getSource: (state) => (id) => SourceTypes.find(m => m.id === id),
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },
    setDashboardItems(state, data) {
      state.dashboardData = {...state.dashboardData, ...data}
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = ReportResource[filters.report](ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    fetchDashboard({state, commit}, filters = {}) {
      if (!state.fetchingDashboard[filters.report]) {
        commit('setDashboardItems', {[filters.report]: null})
        Vue.set(state.fetchingDashboard, filters.report, ReportResource[filters.report](ut.objFilter(filters))
          .then((response) => {
            commit('setDashboardItems', {[filters.report]: response.body})
          })
          .finally(() => Vue.set(state.fetchingDashboard, filters.report, false))
        )
      }

      return state.fetchingDashboard[filters.report]
    },

    details({state, commit}, id) {
      return ReportResource.details({id})
    },
  },
}
