export default {
  data() {
    return {
      formDialog: false,
      detailDialog: false,

      fromDateMenu: false,
      toDateMenu: false,
    }
  },

  methods: {
    showItemDetails(item) {
      this.setModel(item)
      this.detailDialog = true
    },

    showDetails(item) {
      this.show(item.id)
      this.detailDialog = true
    },

    closeDetails() {
      this.detailDialog = false
      this.setModel({})
    },

    closeForm() {
      this.formDialog = false
      this.setModel({})
      this.fetch(this.filter)
    },

    askRemove(item, ev) {
      if (confirm(`Вы уверены удалить запись №${item.id}`)) {
        this.remove(item.id)
            .finally(() => {
              if (ev) {
                this.$eventBus.$emit(ev)
              }
            })
      }
    },
  },
}
