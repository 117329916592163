<template>
  <v-card :loading="!!fetchingDetails">
    <v-card-title>
      Приёмка #{{model.id}} от {{model.reg_date|date}} в склад "{{model.warehouse_name}}"
      <v-chip v-if="model.currency === 'usd'" color="indigo" class="ml-2" dark>в валюте</v-chip>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <table class="w-100 text-body-1">
        <tr>
          <td class="w-50">
            <div>Номер регистрации: {{model.reg_number}}</div>
<!--            <div>Номер приемки: {{model.income_number}}</div>-->
          </td>
          <td class="w-50">
            <div>Контрагент: {{model.counteragent_full_name}} {{model.counteragent_inn ? `(ИНН: ${model.counteragent_inn})` : ''}}</div>
<!--            <div>Контракт: {{model.contract_code}} | {{model.contract_reg_number}} {{model.contract_comment ? ' - ' + model.contract_comment : ''}}</div>-->
          </td>
        </tr>
      </table>

      <h2 class="mt-5 mb-2 font-weight-regular text--primary">Элементы ({{(model.priyomka_elements || []).length}})</h2>
      <v-row>
        <v-col cols="12">
          <v-data-table hide-default-footer disable-items-per-page :items-per-page="1000"
                        :headers="transferItemHeaders" :items="model.priyomka_elements"
          >
            <template #item="{item, index}">
              <tr>
                <td class="text-center">{{index + 1}}</td>
                <td>{{item.raw_material_name}}</td>
                <td>{{item.amount}}</td>
                <td>{{(getMeasurement(item.raw_material_measurement) || {}).name}}</td>
                <td class="text-right">{{item.price|money(model.currency)}}</td>
                <td class="text-center">{{item.discount_percent ? `-${item.discount_percent}%` : '-'}}</td>
                <td class="text-right">{{item.total|money(model.currency)}}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6" class="d-flex align-end">
          <v-btn v-if="!compact" color="primary" dark rounded @click="$emit('dialog:edit', model)">
            <v-icon small left>mdi-pen</v-icon> Изменить
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right">
          <h4>Промежуточный итог:</h4>
          <span class="text-h4">{{rawTotal|money(model.currency)}}</span>
          <h4 class="mt-3">Итого:</h4>
          <span class="text-h4">{{total|money(model.currency)}}</span>
          <div v-if="total - rawTotal" class="success--text">{{(total - rawTotal).toFixed(this.pricePrecision)|money(model.currency)}}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapGetters, createNamespacedHelpers} from 'vuex'
  import hasCurrency from '@/mixins/hasCurrency'

  const {mapState} = createNamespacedHelpers('Transfer')

  export default {
    name: 'transfer-details',

    mixins: [hasCurrency],

    props: {
      compact: {
        type: Boolean,
      }
    },

    data() {
      return {
        transferItemHeaders: [
          {text: '#', align: 'center', width: '70px'},
          {text: 'Материал'},
          {text: 'Кол-во', width: '100px'},
          {text: 'Ед изм', width: '100px'},
          {text: 'Цена', width: '150px', align: 'right'},
          {text: 'Скидка', width: '100px', align: 'center'},
          {text: 'Всего', width: '150px', align: 'right'},
        ],
      }
    },

    computed: {
      ...mapState(['fetchingDetails', 'model']),
      ...mapGetters('Material', ['getMaterial']),
      ...mapGetters('Measurement', ['getMeasurement']),

      total() {
        return (this.model.priyomka_elements || []).reduce((total, ti) => total + (ti.amount || 0) * (ti.price || 0) * (100 - (ti.discount_percent || 0)) / 100, 0).toFixed(this.pricePrecision)
      },
      rawTotal() {
        return (this.model.priyomka_elements || []).reduce((total, ti) => total + (ti.amount || 0) * (ti.price || 0), 0).toFixed(this.pricePrecision)
      },
    },
  }
</script>

<style>
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
</style>
