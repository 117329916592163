<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{model.id ? 'Изменить приемку' : 'Создать приемку'}}</v-card-title>
        <v-divider></v-divider>
        <div v-if="loading || fetching">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
        <v-card-text v-else>
          <v-row>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="reg_date" rules="required|max:256">
                <v-menu ref="transferDate"
                        v-model="transferDateMenu"
                        :disabled="regDateIsBlocked"
                        :close-on-content-click="false"
                        max-width="290px" min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="model.reg_date|date" label="Дата"
                                  prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                                  :disabled="regDateIsBlocked"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="model.reg_date" @input="transferDateMenu = false"
                                 :disabled="regDateIsBlocked"
                                 :allowed-dates="allowedRegDates"
                  ></v-date-picker>
                </v-menu>
              </validation-provider>
            </v-col>
            <v-col cols="5">
              <validation-provider v-slot="{errors}" name="counteragent" rules="required|max:256">
                <v-autocomplete v-model="model.counteragent"
                                :items="counteragents"
                                @change="updateContractsList"
                                item-value="id" item-text="full_name"
                                label="Контрагент"
                                :loading="counteragentsLoading"
                                required :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="2">
              <v-select v-model="model.currency" class="inner-elevation-0"
                        :items="currencies" label="Валюта"
                        item-value="id" item-text="title" hide-details
                        @change="updateItemPrices"
              ></v-select>
            </v-col>
            <v-col cols="9"></v-col>
            <v-col cols="9">
              <p class="text-h6">Элементы (всего позиций: {{(transferItems || []).length}})</p>

              <template v-for="(item, index) in transferItems">
                <v-row>
                  <v-col cols="4">
                    <validation-provider v-slot="{errors}" :name="`raw_material.${index}`" rules="required|double">
                      <v-autocomplete v-model="item.raw_material"
                                      :items="materials"
                                      @change="setItemDefaults(index)"
                                      item-value="id" item-text="name"
                                      required :error-messages="errors"
                                      label="Сырье" dense
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="2">
                    <validation-provider v-slot="{errors}" :name="`amount.${index}`" rules="required|double|positive">
                      <v-text-field v-model="item.amount"
                                    required :error-messages="errors"
                                    @keyup="setItemPrice(index)"
                                    label="Кол-во" dense
                                    :suffix="getSuffix(item)"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="2">
                    <validation-provider v-slot="{errors}" :name="`price.${index + 1}`"  rules="required|double">
                      <v-text-field v-model="item.price"
                                    @keyup="setItemPrice(index)"
                                    required :error-messages="errors"
                                    label="Цена" dense :suffix="priceSuffix"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="1">
                    <validation-provider v-slot="{errors}" :name="`discount.${index + 1}`" rules="required|double">
                      <v-text-field v-model="item.discount_percent"
                                    @keyup="setItemPrice(index)"
                                    required :error-messages="errors"
                                    label="Скидка" dense suffix="%"
                                    @click:append-outer="removeItem(index)"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field v-model="item.total" readonly
                                  append-outer-icon="mdi-close" label="Всего" dense
                                  @click:append-outer="removeItem(index)"
                                  :suffix="priceSuffix"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>

              <v-btn @click="createItem" color="primary" small
                :loading="materialsLoading" :disabled="materialsLoading"
              >
                <v-icon small left>mdi-plus</v-icon> Добавить сырье
              </v-btn>
            </v-col>
            <v-col cols="3" class="text-right">
              <h4>Промежуточный итог:</h4>
              <span class="display-1">{{rawTotal|money(model.currency)}}</span>
              <h4 class="mt-3">Итого:</h4>
              <span class="display-1">{{total|money(model.currency)}}</span>
              <div class="success--text">{{parseFloat((total - rawTotal).toFixed(pricePrecision))|money(model.currency)}}</div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 mt-2 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving || !!fetching"
                 :disabled="!!saving || !!fetching || invalid"
                 rounded color="success"
          >
            <v-icon left>mdi-content-save</v-icon>
            {{model.id ? 'Сохранить' : 'Создать'}}
          </v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          >
            <v-icon left>mdi-cancel</v-icon>
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
  import moment from 'moment'

  export default {
    name: 'transfer-form',

    data() {
      return {
        transferDateMenu: false,
        loading: true,
      }
    },

    computed: {
      ...mapState('Transfer', ['saving', 'fetching', 'model', 'transferItems']),

      ...mapState('Counteragent', {
        counteragents: state => state.data?.results || [],
        counteragentsLoading: state => !!state.fetching,
      }),

      ...mapState('Contract', {
        contracts: state => state.data?.results || [],
        contractsLoading: state => !!state.fetching,
      }),

      ...mapState('Warehouse', {
        warehouses: state => state.data?.results || [],
      }),

      ...mapState('Material', {
        materials: state => (state.data?.results || []).map(material => {
          material.name = `${material.name}`
          return material
        }),
        materialsLoading: state => !!state.fetching,
      }),

      ...mapState('CurrencyRate', {
        currencies: state => state.Currencies,
      }),

      ...mapGetters('Measurement', ['getMeasurement']),
      ...mapGetters('Material', ['getMaterial']),
      ...mapGetters('CurrencyRate', ['getCurrency']),

      total() {
        return parseFloat((this.transferItems || []).reduce((total, ti) => total + (ti.amount || 0) * (ti.price || 0) * (100 - (ti.discount_percent || 0)) / 100, 0).toFixed(this.pricePrecision))
      },
      rawTotal() {
        return parseFloat(((this.transferItems || []).reduce((total, ti) => total + (ti.amount || 0) * (ti.price || 0), 0)).toFixed(this.pricePrecision))
      },

      regDateIsBlocked() {
        return !this.userService.can('can_priyomka_all_date')
      },

      priceField() {
        return this.model.currency === 'usd' ? 'buy_price_usd' : 'buy_price'
      },

      pricePrecision() {
        return 2;
        return this.getCurrency(this.model.currency).precision
      },

      priceSuffix() {
        return this.getCurrency(this.model.currency).format
      }
    },

    methods: {
      ...mapMutations('Transfer', [
        'createItem', 'setModel', 'setItem', 'updateModel'
      ]),
      ...mapActions('Transfer', [
        'removeItem',
      ]),

      allowedRegDates(val) {
        return moment().isAfter(moment(val))
      },

      updateContractsList(counteragent) {
        if (!this.model.id) {
          this.updateModel({contract: null})
        }
        this.$store.dispatch('Contract/fetch', {counteragent})
      },

      setItemDefaults(index) {
        let item = this.transferItems[index]
        item.price = this.getMaterial(item.raw_material)[this.priceField] || 0
        item.total = parseFloat((item.price * item.amount).toFixed(this.pricePrecision))

        this.setItem({index, item})
      },

      setItemPrice(index) {
        let item = this.transferItems[index]
        item.total = parseFloat((item.amount * item.price * (100 - item.discount_percent) / 100).toFixed(this.pricePrecision))

        this.setItem({index, item})
      },

      updateItemPrices() {
        this.$nextTick(() => this.transferItems.forEach((item, i) => {
          item.price = parseFloat((this.getMaterial(item.raw_material)[this.priceField] || 0).toFixed(this.pricePrecision))
          this.setItemPrice(i)
        }))
      },

      getSuffix(item) {
        if (!item.raw_material) {
          return ''
        }
        let measurementId = this.getMaterial(item.raw_material)?.measurement
        return (measurementId && this.getMeasurement(measurementId)?.name) || ''
      },

      cancel(reset) {
        reset()
        this.$emit('transfer:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Transfer/save')
          .then(() => {
            this.$emit('transfer:form:saved')
          })
      },
    },

    mounted() {
      Promise.all([
        new Promise((resolve, reject) => {
          if (this.fetching && typeof this.fetching === 'object') {
            this.fetching.then(() => {
              this.$store.dispatch('Contract/fetch', {counteragent: this.model.counteragent})
                .then(resolve)
                .catch(reject)
            })
          } else {
            if (this.model.counteragent) {
              this.$store.dispatch('Contract/fetch', {counteragent: this.model.counteragent})
                .then(resolve)
                .catch(reject)
            } else {
              resolve()
            }
          }
        }),
        this.$store.dispatch('Counteragent/fetch'),
        this.$store.dispatch('Warehouse/fetch', {type: "raw_material"}).then(() => {
          if (!this.model.id && this.warehouses.length === 1) {
            this.updateModel({warehouse: this.warehouses[0].id})
          }
        }),
        this.$store.dispatch('Material/fetch'),
        this.$store.dispatch('Measurement/fetch'),
      ]).then(() => this.loading = false)
    }
  }
</script>

<style>
  .inner-elevation-0 .v-input__slot {
    box-shadow: none!important;
  }
</style>
