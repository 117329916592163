<template>
  <v-card :loading="!!fetching">
    <v-card-title>
      Рецепт #{{model.id}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="text-body-1">
      <div>Название: {{model.name}}</div>
      <div>Описание: {{model.description}}</div>
    </v-card-text>
    <v-card-text class="text-body-1">
      <h2 class="mt-4 mb-3 font-weight-regular text--primary">Элементы ({{(recipeItems || []).length}})</h2>
      <v-row><v-col cols="12">
        <v-data-table hide-default-footer disable-items-per-page :items-per-page="1000"
                      :headers="recipeItemHeaders" :items="recipeItems"
        >
          <template #item="{item, index}">
            <tr>
              <td class="text-center">{{index + 1}}</td>
              <td>{{item.raw_material_name}}</td>
              <td class="text-right">{{item.amount}}</td>
              <td>{{(getMeasurement(item.raw_material_measurement) || {}).name}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col></v-row>
      <v-btn color="primary" dark class="mt-5" rounded @click="$emit('dialog:edit', model)">
        <v-icon small left>mdi-pen</v-icon> Изменить
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapGetters, createNamespacedHelpers} from 'vuex'

  const {mapState} = createNamespacedHelpers('Recipe')

  export default {
    name: 'recipe-details',

    data() {
      return {
        recipeItemHeaders: [
          {text: '#', align: 'center', width: '70'},
          {text: 'Материал'},
          {text: 'Кол-во', align: 'right', width: '150px'},
          {text: 'Ед изм', width: '100px'},
        ],
      }
    },

    computed: {
      ...mapState(['fetching', 'model', 'recipeItems']),
      ...mapGetters('Material', ['getMaterial']),
      ...mapGetters('Measurement', ['getMeasurement']),
    },
  }
</script>
