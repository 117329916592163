<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{model.id ? 'Изменить универсальный расход' : 'Создать универсальный расход'}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="reg_date" rules="required">
                <v-menu ref="transferDate"
                        v-model="dateMenu"
                        :disabled="regDateIsBlocked"
                        :close-on-content-click="false"
                        max-width="290px" min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="model.reg_date|date" label="Дата"
                                  prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                                  :disabled="regDateIsBlocked"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="model.reg_date" @input="dateMenu = false"
                                 :disabled="regDateIsBlocked"
                                 :allowed-dates="allowedRegDates"
                  ></v-date-picker>
                </v-menu>
              </validation-provider>
            </v-col>
            <v-col cols="7">
              <validation-provider v-slot="{errors}" name="counteragent" rules="required|max:256">
                <v-autocomplete v-model="model.counteragent"
                                :items="counteragents"
                                item-value="id" item-text="full_name"
                                label="Контрагент"
                                :loading="counteragentsLoading"
                                required :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="amount" rules="required|double">
                <v-text-field v-model="model.amount"
                              required :error-messages="errors" hide-details
                              label="Цена" suffix="сум"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="type" rules="required">
                <v-autocomplete v-model="model.type"
                                :items="UniversalExpenseTypes"
                                item-value="id" item-text="label"
                                required :error-messages="errors" hide-details
                                label="Тип"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="9">
              <validation-provider v-slot="{errors}" name="description" rules="max:256">
                <v-text-field v-model="model.description"
                              :error-messages="errors"
                              label="Комментарий"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 mt-2 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving || !!fetching"
                 :disabled="!!saving || !!fetching || invalid"
                 rounded color="success"
          >
            <v-icon left>mdi-content-save</v-icon>
            {{model.id ? 'Сохранить' : 'Создать'}}
          </v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          >
            <v-icon left>mdi-cancel</v-icon>
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState} from 'vuex'
  import moment from 'moment'

  export default {
    name: 'universal-expense-form',

    data() {
      return {
        dateMenu: false,
      }
    },

    computed: {
      ...mapState('UniversalExpense', ['UniversalExpenseTypes', 'saving', 'fetching', 'model']),

      ...mapState('Counteragent', {
        counteragents: state => (state.data?.results || []),
        counteragentsLoading: state => !!state.fetching,
      }),

      regDateIsBlocked() {
        return !this.userService.can('can_priyomka_all_date')
      },
    },

    methods: {
      allowedRegDates(val) {
        return moment().isAfter(moment(val))
      },

      cancel(reset) {
        reset()
        this.$emit('universal-expense:form:cancel')
      },

      save() {
        this.$store
          .dispatch('UniversalExpense/save')
          .then(() => {
            this.$emit('universal-expense:form:saved')
          })
      },
    },
  }
</script>
