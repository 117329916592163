import VueResource from 'vue-resource'
import router      from './index'
import store       from '@/store'
import cookie      from 'cookie'

export default {
  install(VueInstance) {
    VueInstance.use(VueResource)

    VueInstance.http.options.root = process.env.VUE_APP_API_URL

    VueInstance.http.interceptors.push((request, next) => {
      request.headers.set('Content-Type', 'application/json')
      request.headers.set('Accept', 'application/json')

      let token = localStorage.getItem('access_token')
      if (token) {
        request.headers.set('Authorization', 'Bearer ' + token)
      }

      next()
    })

    VueInstance.http.interceptors.push(function (request, next) {
      next((response) => {
        if (!response.ok) {
          let message = ''
          switch (response.status) {
            case 422:
              message = response.body.detail
              break
            case 401:
              message = 'Сессия истекла!'
              store.commit('Auth/removeTokens')
              if (router.currentRoute.name !== 'login') {
                router.push({name: 'login'})
              }
              break
            case 419:
              message = 'Сессия истекла'
              store.dispatch('Auth/removeTokens')
              if (router.currentRoute.name !== 'login') {
                router.push({name: 'login'})
              }
              break
            default:
              message = 'Что-то пошло не так!'
          }

          VueInstance.$eventBus.$emit('notification:error', message)
        }
      })
    })
  }
}
