<template>
  <v-app id="warehouse">
    <v-navigation-drawer :mini-variant="miniDrawer" v-model="showMenu" :temporary="$vuetify.breakpoint.mdAndDown" app clipped v-if="user.id">
      <v-list dense>
        <template v-for="(menuGroup, idx) in menus" v-if="menuGroup.items">
          <v-list-group :value="hasActiveRoute(menuGroup)">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{menuGroup.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{menuGroup.title}}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(menuItem, index) in menuGroup.items" :key="index"
                         :to="{name: menuItem.routeName}" active-class="primary" color="white"
            >
              <v-list-item-icon>
                <v-icon right>{{menuItem.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{menuItem.title}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-divider></v-divider>
        </template>
        <template v-else>
          <v-list-item :to="{name: menuGroup.routeName}" :input-value="$route.name === menuGroup.routeName"
                       active-class="primary" color="white"
          >
            <v-list-item-icon>
              <v-icon>{{menuGroup.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{menuGroup.title}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-list>

      <div class="pt-3 text-center">
        <v-icon @click="miniDrawer = !miniDrawer">{{miniDrawer ? 'mdi-chevron-right' : 'mdi-chevron-left'}}</v-icon>
      </div>
      <div v-if="!miniDrawer" class="pt-3 text-center">
        <div class="text-caption text--disabled">The WareHouse v{{version}} | {{year}}</div>
      </div>
    </v-navigation-drawer>
    <v-app-bar ref="globalHeaderContainer" dark app dense clipped-left color="primary">
      <v-app-bar-nav-icon @click="showMenu = !showMenu" v-if="user.id">
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon>
        <v-icon>mdi-warehouse</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title class="pl-0">Складский учёт</v-app-bar-title>

      <v-btn v-if="showBackButton" color="primary" depressed class="ml-4"
             @click="$router.back()"
      >
        <v-icon left>mdi-arrow-left</v-icon>назад
      </v-btn>

      <v-spacer></v-spacer>

      <currency-rate-toolbar dark clickable/>

      <v-menu v-if="user.id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-account</v-icon></v-btn>
        </template>
        <v-list dense>
          <v-list-item :to="{name: 'profile'}">
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
            <v-list-item-title>{{user.username}}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon><v-icon>mdi-exit-to-app</v-icon></v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view v-if="inited"></router-view>
      <div v-else style="text-align: center;" class="mt-10">
        <v-progress-circular width="2" indeterminate></v-progress-circular>
      </div>

    </v-main>
    <v-snackbar v-model="notification" :timeout="3000" :color="notificationError ? 'error' : 'success'" top right>
      {{ notificationText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="notification = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
  import {mapState, mapGetters} from 'vuex'
  import {version} from '../package.json'
  import currencyRateToolbar from '@/modules/currency-rates/toolbar'

  export default {
    name: 'App',

    data() {
      return {
        version,
        year: (new Date).getFullYear(),
        showMenu: true,

        title: '',

        notificationText: '',
        notification: false,
        notificationError: false,

        menus: [],
      }
    },

    computed: {
      showBackButton() {
        return (this.$route.name || '').startsWith('page.') && this.$route.name !== 'page.welcome'
      },

      ...mapState(['inited']),
      ...mapState('Auth', ['user']),

      miniDrawer: {
        get() {
          return this.$store.state.miniDrawer
        },
        set() {
          this.$store.commit('resizeDrawer')
        }
      },
      ...mapGetters('User', ['getRole']),
      },

    methods: {
      hasActiveRoute(menuGroup) {
        return menuGroup.items.filter(r => r.routeName === this.$route.name).length
      },

      logout() {
        this.$store.commit('Auth/removeTokens')
        this.$router.push({name: 'login'})
      },
    },

    created() {
      this.menus = this.routeService.menuList()

      this.$eventBus.$on('auth:updated', () => {
        this.menus = this.routeService.menuList()
      })

      this.$eventBus.$on('setTitle', (val) => {
        this.title = val
        document.title = val
      })

      this.$eventBus.$on('notification:success', (val) => {
        this.notificationText = val
        this.notification = true
        this.notificationError = false
      })
      this.$eventBus.$on('notification:error', (val) => {
        this.notificationText = val
        this.notification = true
        this.notificationError = true
      })
    },

    beforeMount() {
      this.$eventBus.$emit('setTitle', process.env.VUE_APP_APP_TITLE)
      this.$store.dispatch('load')
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => vm.$auth.check())
    },

    beforeRouteUpdate() {
      this.$auth.check()
    },

    components: {
      currencyRateToolbar,
    }
  };
</script>

<style>
.link-like {
  cursor: pointer;
}
</style>
