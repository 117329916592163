<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="d-flex align-center">
        <span class="text-h5">Список складов</span>
        <v-dialog v-model="formDialog" width="500" persistent>
          <template #activator="{on, attrs}">
            <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary"
                   v-bind="attrs" v-on="on" small
            >
              <v-icon left>mdi-plus</v-icon> Добавить
            </v-btn>
          </template>
          <warehouse-form v-if="formDialog"
                          @warehouse:form:saved="closeForm"
                          @warehouse:form:cancel="closeForm"
          ></warehouse-form>
        </v-dialog>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="warehouses"
      :loading="!!fetching"
      multi-sort
      :sort-by="filter.sortColumns"
      :sort-desc="filter.sortOrders"
      disable-items-per-page hide-default-footer
      :options.sync="options"
      no-data-text="Нет данных"
      loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.name}}</td>
          <td>{{types.find(t => t.id === item.type).title}}</td>
          <td class="text-right">
<!--            <v-btn class="ml-2" text small depressed color="success" @click="showDetails(item)">-->
<!--              <v-icon left small>mdi-eye</v-icon>-->
<!--              Показать-->
<!--            </v-btn>-->
            <v-btn class="ml-2" text small depressed icon color="primary" dark @click="showForm(item)">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
            <v-btn class="ml-2" text small depressed icon color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination v-model="page" :total-visible="10" :length="totalPages" :disabled="totalPages === 1"></v-pagination>
    </div>
    <v-dialog v-model="detailDialog" width="60vw">
      <warehouse-details @dialog:close="closeDetails"></warehouse-details>
    </v-dialog>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('Warehouse')

  import WarehouseDetails from '@/modules/warehouse/details'
  import WarehouseForm from '@/modules/warehouse/form'

  export default {
    name: 'warehouse-list',

    data() {
      return {
        formDialog: false,
        detailDialog: false,

        filter: {
          page: 1,
          limit: 10,
          search: null,
          sortBy: [],
          sortDesc: [],
        },

        options: {
          sortBy: [],
          sortDesc: [],
        },
      }
    },

    watch: {
      'options.sortBy': {
        handler(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.filter.sortBy = newVal
            this.filter.page = 1
            this.fetch(this.filter)
          }
        },
        deep: true,
      },
      'options.sortDesc': {
        handler(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.filter.sortDesc = newVal
            this.filter.page = 1
            this.fetch(this.filter)
          }
        },
        deep: true,
      },
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
        'deleting',
        'types',
      ]),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Название', value: 'name',},
          {text: 'Тип', value: 'type',},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      warehouses() {
        return this.data?.results || []
      },

      totalPages() {
        return Math.ceil((this.data || {count: 0}).count / this.filter.limit)
      },

      page: {
        get() {
          return this.filter.page
        },

        set(val) {
          this.filter.page = val
          this.fetch(this.filter)
        }
      }
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel']),

      showDetails(item) {
        this.detailDialog = true
        this.show(item.id)
      },

      closeDetails() {
        this.detailDialog = false
        this.setModel({})
      },

      showForm(item) {
        this.formDialog = true
        this.setModel(item)
      },

      closeForm() {
        this.formDialog = false
        this.setModel({})
        this.fetch(this.filter)
      },

      askRemove(item) {
        if (confirm(`Вы уверены удалить ${item.name}`)) {
          this.remove(item.id)
        }
      }
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Список складов')
    },

    components: {
      WarehouseForm,
      WarehouseDetails,
    }
  }
</script>
