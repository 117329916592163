import {Resource} from 'vue-resource'
import ut from '@/utils'
import {SERVER_DATE_FORMAT} from '@/filters'
import moment from 'moment'

const CurrencyRate = {
  id: null,
  updated_at: '',
  rate: 0,
}

export const Currencies = [
  {id: 'uzs', title: 'в сумах', format: 'сум', precision: 0,},
  {id: 'usd', title: 'в долларах', format: '$', precision: 2,},
]

const CurrencyRateResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'payment/currency-rate/list/',
  },
  current: {
    method: 'GET',
    url: 'payment/currency-rate/latest/',
  },
  create: {
    method: 'POST',
    url: 'payment/currency-rate/create/',
  },
})

const Conversion = {
  id: null,
  reg_number: null,
  reg_date: moment().format(SERVER_DATE_FORMAT),
  type: 'credit',
  source: 'conversion',
  cash_type: 'transfer',
  currency: 'uzs',
  total: 0,
  comments: '',
  conversion_payment: {
      is_expenditure: true,
      total: 0,
      currency: 'usd',
      cash_type: 'transfer',
  }
}

const ConversionResource = new Resource('/', {}, {
    fetchBalance: {
      method: 'GET',
      url: 'payment/account-balances/',
    },
    create: {
      method: 'POST',
      url: 'payment/create/',
    },
})

export default {
  namespaced: true,

  state: {
    Currencies,

    data: null,
    current: null,
    fetching: false,
    saving: false,
    fetchingCurrent: false,
    model: ut.deepClone(CurrencyRate),

    accountBalances: {},
    fetchingBalances: false,
    converting: false,
    conversion: ut.deepClone(Conversion),
  },

  getters: {
    getCurrency: (state) => (id) => Currencies.find(c => c.id === id) || Currencies[0],
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setCurrent(state, data) {
      state.current = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(CurrencyRate), ...data}
    },

    setConversion(state, data) {
      state.conversion = {...ut.deepClone(Conversion), ...data}
    },
  },

  actions: {
    current({state, commit}) {
      if (!state.fetchingCurrent) {
        state.fetchingCurrent = CurrencyRateResource
                                .current()
                                .then((response) => {
                                  commit('setCurrent', response.body)
                                })
                                .finally(() => state.fetchingCurrent = false)
      }

      return state.fetchingCurrent
    },

    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = CurrencyRateResource
                            .list({...ut.objFilter(filters), offset: filters.limit * (filters.page - 1)})
                            .then((response) => {
                              commit('setItems', response.body)
                            })
                            .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    save({state, commit, dispatch}) {
      state.saving = true
      let promise
      if (state.model.id) {
        promise = CurrencyRateResource.update({id: state.model.id}, ut.objFilter(state.model))
      } else {
        promise = CurrencyRateResource.create(ut.objFilter(state.model))
      }

      return promise
        .then(({body}) => {
          commit('setModel', body)
          dispatch('current')
        })
        .finally(() => state.saving = false)
    },

    fetchBalance({state}, ) {
      if (!state.fetchingBalances) {
        state.fetchingBalances = ConversionResource.fetchBalance()
                                  .then(({body}) => {
                                    state.accountBalances = body.result
                                  })
                                  .finally(() => state.fetchingBalances = false)
      }

      return state.fetchingBalances
    },

    conversion({state, commit}) {
      state.converting = ConversionResource.create(ut.objFilter(state.conversion))
                            .then(({body}) => {
                              commit('setConversion', body)
                            })
                            .finally(() => state.converting = false)
    }
  },
}
