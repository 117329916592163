<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="12" class="d-flex align-center pb-0">
        <span class="text-h5">Список продуктов</span>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-menu v-model="fromDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-inner-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.product_doc__reg_date_after" :disabled="!!fetching"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-menu v-model="toDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-inner-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.product_doc__reg_date_before" :disabled="!!fetching"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-autocomplete v-model="filter.product_type"
                        :items="products"
                        :loading="productsLoading"
                        item-value="id" item-text="name"
                        label="Продукты" clearable hide-details
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-text-field v-model="filter.amount_min" :disabled="!!fetching" type="number"
                      label="Мин кол-во" prepend-inner-icon="mdi-format-horizontal-align-left"
                      clearable hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-text-field v-model="filter.amount_max" :disabled="!!fetching" type="number"
                      label="Макс кол-во" append-icon="mdi-format-horizontal-align-right"
                      clearable hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="nonSortableHeaders"
      :items="productItems"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных"
      loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td class="text-center">{{item.reg_date|date}}</td>
          <td class="text-center">{{item.product_doc}}</td>
          <td>{{item.product_type.name}}</td>
          <td class="text-right">{{item.amount}}</td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>
  </v-container>
</template>

<script>
  import {mapState as defaultMapState, createNamespacedHelpers} from 'vuex'

  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('ProductItem')

  import ProductItemDetails from '@/modules/product-item/details'
  import moment from 'moment'
  import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'
  import {hasDialog, hasFilter} from '@/mixins'

  export default {
    name: 'product-item-list',

    mixins: [hasFilter, hasDialog],

    customFilters: {
      product_type: null,
      product_doc__reg_date_after: moment().subtract(1, 'month').format(SERVER_DATE_FORMAT),
      product_doc__reg_date_before: moment().format(SERVER_DATE_FORMAT),
      amount_min: '',
      amount_max: '',
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
        'deleting',
      ]),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Дата', align: 'center', width: '200px'},
          {text: 'Рег номер документа', align: 'center', width: '15s0px'},
          {text: 'Тип продукта'},
          {text: 'Кол-во', align: 'right'},
        ]
      },

      productItems() {
        return this.data?.results || []
      },

      fromDateFormatted() {
        return moment(this.filter.product_doc__reg_date_after).format(USER_DATE_FORMAT)
      },

      toDateFormatted() {
        return moment(this.filter.product_doc__reg_date_before).format(USER_DATE_FORMAT)
      },

      ...defaultMapState('Product', {
        products: state => (state.data?.results || []),
        productsLoading: state => !!state.fetching,
      }),
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel']),
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Список документов продукта')
      this.$store.dispatch('Product/fetch')
      this.$store.dispatch('Warehouse/fetch')
    },

    components: {
      ProductItemDetails,
    }
  }
</script>
