var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.model.id ? 'Изменить траспортный расход' : 'Создать траспортный расход'))]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"reg_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"transferDate",attrs:{"disabled":_vm.regDateIsBlocked,"close-on-content-click":false,"max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm._f("date")(_vm.model.reg_date),"label":"Дата","prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.regDateIsBlocked}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"disabled":_vm.regDateIsBlocked,"allowed-dates":_vm.allowedRegDates},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.model.reg_date),callback:function ($$v) {_vm.$set(_vm.model, "reg_date", $$v)},expression:"model.reg_date"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"counteragent","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.counteragents,"item-value":"id","item-text":"full_name","label":"Контрагент","loading":_vm.counteragentsLoading,"required":"","error-messages":errors},model:{value:(_vm.model.counteragent),callback:function ($$v) {_vm.$set(_vm.model, "counteragent", $$v)},expression:"model.counteragent"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"hide-details":"","label":"Цена","suffix":"сум"},model:{value:(_vm.model.amount),callback:function ($$v) {_vm.$set(_vm.model, "amount", $$v)},expression:"model.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.TransportExpenseTypes,"item-value":"id","item-text":"label","required":"","error-messages":errors,"hide-details":"","label":"Тип"},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"transport","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.transports,"item-value":"id","item-text":"label","required":"","error-messages":errors,"hide-details":"","label":"Транспортное средство"},model:{value:(_vm.model.transport),callback:function ($$v) {_vm.$set(_vm.model, "transport", $$v)},expression:"model.transport"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('validation-provider',{attrs:{"name":"description","rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Комментарий"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-space-between pb-4 mt-2 px-4"},[_c('v-btn',{attrs:{"loading":!!_vm.saving || !!_vm.fetching,"disabled":!!_vm.saving || !!_vm.fetching || invalid,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.model.id ? 'Сохранить' : 'Создать')+" ")],1),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Отмена ")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }