import ut from '@/utils'

export default {
  methods: {
    buildFilters() {
      let filters = {
        page: 1,
        limit: parseInt(localStorage.getItem(this.$options.name + '_limit') || 10),
        sortBy: [],
        sortDesc: [],
        ...(this.$options.customFilters || {}),
      }

      Object.entries(this.$options.storedFilters || {}).forEach(([field, type]) => {
        switch (type.name) {
          case 'String':
            filters[field] = localStorage.getItem(this.$options.name + field) || filters[field]
            break
          default:
            break
        }
      })

      for (let filter in filters) {
        let filterVal = this.$route.query[filter]

        if (filterVal) {
          if (isNaN(+filterVal)) {
            filters[filter] = filterVal
          } else {
            filters[filter] = +filterVal
          }
        }
      }

      return filters
    },

    compileFilters() {
      return {
        ...this.filter,
        offset: (this.filter.limit || 0) * ((this.filter.page || 1) - 1)
      }
    }
  },

  data() {
    return {
      _timerId: null,
      oldFilter: {},
      filter: this.buildFilters()
    }
  },

  watch: {
    filter: {
      deep: true,
      handler(val) {
        Object.keys(this.$options.storedFilters || {}).forEach(field => {
           localStorage.setItem(this.$options.name + field, this.filter[field])
        })

        if (this.oldFilter.page === val.page) {
          this.filter.page = 1
        }

        let returnFocusTo = null
        if (this.$options.refocusable) {
          for (let field in this.$options.refocusable) {
            if (this.oldFilter[field] !== val[field]) {
              returnFocusTo = this.$options.refocusable[field]
              break
            }
          }
        }

        this.oldFilter = ut.deepClone(val)

        if (this._timerId) {
          clearTimeout(this._timerId);
        }

        this._timerId = setTimeout(() => {
          this.$nextTick(() => {
            this.fetch(this.compiledFilters)
              .then(() => {
                if (returnFocusTo) {
                  this.$refs[returnFocusTo]?.focus()
                }
              })
          })
        }, returnFocusTo ? 1500 : 400) // typed fields needs more debounce
      },
    },
  },

  computed: {
    nonSortableHeaders() {
      return this.headers.map(h => {
        h.sortable = false
        return h
      })
    },

    compiledFilters() {
      return this.compileFilters()
    },

    totalPages() {
      return Math.ceil((this.data || {count: 0}).count / this.filter.limit)
    },

    page: {
      get() {
        return this.filter.page
      },

      set(val) {
        this.filter.page = val
      }
    },

    limit: {
      get() {
        return this.filter.limit
      },

      set(val) {
        localStorage.setItem(this.$options.name + '_limit', parseInt(val))
        this.filter.limit = val
      }
    }
  },

  mounted() {
    this.oldFilter = ut.deepClone(this.filter)
  }
}
