<template>
  <v-card :loading="!!fetching">
    <v-card-title>
      Договор #{{model.id}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row class="text-body-1">
        <v-col cols="6">
          <div>Код: {{model.code}}</div>
          <div>Регистрационный номер: {{model.reg_number}}</div>
        </v-col>
        <v-col cols="6" class="text-right">
          <div>Контрагент: {{model.counteragent_full_name}}</div>
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-2"></v-divider>
      <div>Комментарий: {{model.comment}}</div>
      <div class="text-right text-h5">Сумма: {{model.price|money}}</div>
    </v-card-text>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState} = createNamespacedHelpers('Contract')

  export default {
    name: 'contract-details',

    computed: {
      ...mapState(['fetching', 'model']),
    },
  }
</script>
