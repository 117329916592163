import Vue from 'vue'
import ut from '@/utils'
import {SERVER_DATE_FORMAT} from '@/filters'
import moment from 'moment'

import {Resource} from 'vue-resource'

export const UniversalExpenseTypes = [
  {id: 'product', label: 'продукт'},
  {id: 'counteragent', label: 'расчеты с контрагентом'},
  {id: 'staff_salary', label: 'расчеты с сотрудником (зарплата)'},
  {id: 'capital', label: 'капитал'},
  {id: 'transport_expenses', label: 'затраты на транспорт'},
  {id: 'plant_expenses', label: 'затраты на завод'},
  {id: 'eat_expenses', label: 'затраты на питания'},
  {id: 'nonrefundable_expenses', label: 'затраты без возврата'},
  {id: 'bank_credit_expenses', label: 'затраты на кредиты'},
  {id: 'bank_credit_percent_expenses', label: 'затраты на проценты кредита'},
  {id: 'bank_commissions_expenses', label: 'затраты на банковское комиссии'},
  {id: 'tax_expenses', label: 'затраты на налог'},
  {id: 'ipoteka_expenses', label: 'затраты на ипотека'},
  {id: 'conversion', label: 'конверсия'},
  {id: 'other_expenses', label: 'другие затраты'},
]

const UniversalExpense = {
  id: null,
  reg_date: moment().format(SERVER_DATE_FORMAT),
  type: UniversalExpenseTypes[0].id,
  counteragent: null,
  description: '',
  amount: null,
}

const UniversalExpenseResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'reports/universal-expenditurs/list/',
  },
  details: {
    method: 'GET',
    url: 'reports/universal-expenditurs/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'reports/universal-expenditurs/create/',
  },
  update: {
    method: 'PUT',
    url: 'reports/universal-expenditurs/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'reports/universal-expenditurs/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    UniversalExpenseTypes,

    data: null,

    fetching: false,
    fetchingDetails: false,
    model: ut.deepClone(UniversalExpense),

    saving: false,
    deleting: {}
  },

  getters: {
    getType: (state) => (id) => UniversalExpenseTypes.find(e => e.id === id),
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    updateModel(state, data) {
      state.model = {...state.model, ...data}
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(UniversalExpense), ...data}
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = UniversalExpenseResource.list(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    save({state, commit}) {
      state.saving = true

      let promise
      if (state.model.id) {
        promise = UniversalExpenseResource.update({id: state.model.id}, state.model)
      } else {
        promise = UniversalExpenseResource.create(state.model)
      }

      return promise.then(() => {
                commit('setModel', {})
                state.saving = false
             })
             .catch(({body}) => Vue.$eventBus.$emit('notification:error', body.detail))
            .finally(() => state.saving = false)
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return UniversalExpenseResource.remove({id})
              .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
