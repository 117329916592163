import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'

const Warehouse = {
  id: null,
  name: '',
}

const WarehouseResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/warehouse/list/',
  },
  details: {
    method: 'GET',
    url: 'warehouse/warehouse/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'warehouse/warehouse/create/',
  },
  update: {
    method: 'PUT',
    url: 'warehouse/warehouse/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'warehouse/warehouse/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    types: [
      {id: 'raw_material', title: 'Сырьёвой'},
      {id: 'product', title: 'Продуктовый'}
    ],

    data: null,

    fetching: false,
    model: ut.deepClone(Warehouse),

    saving: false,
    deleting: {}
  },

  getters: {
    getWarehouseName: (state) => (id) => (state.data?.results?.find(m => m.id === id) || {}).name,
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(Warehouse), ...data}
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = WarehouseResource.list(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit}, id) {
      if (!state.fetching) {
        commit('setModel', {})
        state.fetching = WarehouseResource.details({id})
          .then(({body}) => {
            commit('setModel', body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },
    save({state, commit}) {
      state.saving = true
      let promise
      if (state.model.id) {
        promise = WarehouseResource.update({id: state.model.id}, state.model)
      } else {
        promise = WarehouseResource.create(state.model)
      }

      return promise
        .then(({body}) => {
          commit('setModel', body)
        })
        .finally(() => state.saving = false)
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return WarehouseResource.remove({id})
        .then(() => dispatch('fetch'))
        .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
