const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

const objFilter = (originalObj, extraFilters = []) => {
  let obj = deepClone(originalObj)
  for (let field in obj) {
    if (field === 'isTrusted' || (obj[field] !== 0 && !obj[field]) || extraFilters.indexOf(field) > -1) {
      delete obj[field]
    }
  }
  return obj
}

const noop = () => {}

export default {
  deepClone,
  objFilter,
  noop
}
