<template>
  <v-card :loading="!!fetchingDetails">
    <v-progress-linear indeterminate v-if="!!fetchingDetails"></v-progress-linear>
    <template v-else>
      <v-card-title>
        {{(getPaymentType(model.type)|| {}).altName2}} #{{model.id}}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('dialog:close')">
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <hr style="height: 3px" :class="getPaymentType(model.type).color2">
      <v-card-text>
        <v-row>
          <v-col cols="6" class="px-0">
            <v-list-item two-line desn>
              <v-list-item-content>
                <v-list-item-subtitle>Дата</v-list-item-subtitle>
                <v-list-item-title>{{model.reg_date|date}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Регистрационный номер</v-list-item-subtitle>
                <v-list-item-title>{{model.reg_number}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Форма платежа</v-list-item-subtitle>
                <v-list-item-title>{{getPaymentForm(model.cash_type).name}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="6" class="px-0">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Тип платежа</v-list-item-subtitle>
                <v-list-item-title>
                  {{getSourceType(model.source).name || ''}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="getSourceType(model.source).hasData" two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Объект платежа</v-list-item-subtitle>
                <v-list-item-title>
                  <span v-if="model.source === 'transport_expenses'">{{model.source_object_info.number}} - {{model.source_object_info.model}}</span>
                  <span v-if="(model.source_object_info || {}).full_name">{{model.source_object_info.full_name}}</span>
                  <span v-else>{{model.source_object_info|full_name}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line v-if="(model.source_object_info || {}).inn">
              <v-list-item-content>
                <v-list-item-subtitle>ИНН</v-list-item-subtitle>
                <v-list-item-title>
                  {{model.source_object_info.inn}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Сумма</v-list-item-subtitle>
                <v-list-item-title>{{model.total|money(model.currency)}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            Комментарий: {{model.comments}}
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'
  const {mapState, mapGetters} = createNamespacedHelpers('Payment')

  export default {
    name: 'payment-details',

    computed: {
      ...mapState(['fetchingDetails', 'model']),
      ...mapGetters(['getSourceType', 'getPaymentType', 'getPaymentForm']),
    },
  }
</script>
