<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <span style="vertical-align:middle;" class="text-h5">Список договоров</span>
        <v-dialog v-model="formDialog" width="500" persistent>
          <template #activator="{on, attrs}">
            <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
                   v-bind="attrs" v-on="on"
            >
              <v-icon left>mdi-plus</v-icon> Добавить
            </v-btn>
          </template>
          <contract-form v-if="formDialog"
                          @contract:form:saved="closeForm"
                          @contract:form:cancel="closeForm"
          ></contract-form>
        </v-dialog>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="contracts"
      :loading="!!fetching"
      multi-sort
      :sort-by="filter.sortColumns"
      :sort-desc="filter.sortOrders"
      disable-items-per-page hide-default-footer
      :options.sync="options"
      no-data-text="Нет данных"
      loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.reg_number}}</td>
          <td>{{item.code}}</td>
          <td>{{item.price|money}}</td>
          <td>{{item.counteragent.full_name}}</td>
          <td>{{item.comment}}</td>

          <td class="text-right">
            <v-btn class="ml-2" text small depressed color="success" @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon>
              Показать
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            ><v-icon left small>mdi-delete</v-icon></v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination v-model="page" :total-visible="10" :length="totalPages" :disabled="totalPages === 1"></v-pagination>
    </div>
    <v-dialog v-model="detailDialog" width="60vw">
      <contract-details @dialog:close="closeDetails"></contract-details>
    </v-dialog>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('Contract')
  const {mapGetters} = createNamespacedHelpers('Counteragent')

  import ContractDetails from '@/modules/contract/details'
  import ContractForm from '@/modules/contract/form'

  export default {
    name: 'contract-list',

    data() {
      return {
        formDialog: false,
        detailDialog: false,

        filter: {
          page: 1,
          limit: 10,
          search: null,
          sortBy: [],
          sortDesc: [],
        },

        options: {
          sortBy: [],
          sortDesc: [],
        },
      }
    },

    watch: {
      'options.sortBy': {
        handler(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.filter.sortBy = newVal
            this.filter.page = 1
            this.fetch(this.filter)
          }
        },
        deep: true,
      },
      'options.sortDesc': {
        handler(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.filter.sortDesc = newVal
            this.filter.page = 1
            this.fetch(this.filter)
          }
        },
        deep: true,
      },
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
        'deleting',
      ]),

      ...mapGetters(['getCounteragent']),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Номер регистрации', value: 'name'},
          {text: 'Сумма'},
          {text: 'Код'},
          {text: 'Контрагент'},
          {text: 'Комментрарий'},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      contracts() {
        return this.data?.results || []
      },

      totalPages() {
        return Math.ceil((this.data || {count: 0}).count / this.filter.limit)
      },

      page: {
        get() {
          return this.filter.page
        },

        set(val) {
          this.filter.page = val
          this.fetch(this.filter)
        }
      }
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel']),

      showDetails(item) {
        this.setModel(item)
        this.detailDialog = true
      },

      closeDetails() {
        this.setModel({})
        this.detailDialog = false
      },

      showForm(item) {
        this.setModel(item)
        this.formDialog = true
      },

      closeForm() {
        this.formDialog = false
        this.setModel({})
        this.fetch(this.filter)
      },

      askRemove(item) {
        if (confirm(`Вы уверены удалить ${item.name}`)) {
          this.remove(item.id)
        }
      }
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Список договоров')
      this.$store.dispatch('Counteragent/fetch')
    },

    components: {
      ContractForm,
      ContractDetails,
    }
  }
</script>
