<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card :loading="!!fetching">
        <v-card-title>{{model.id ? 'Изменить контрагент' : 'Создать контрагент'}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="full_name" rules="required|max:256">
                <v-text-field v-model="model.full_name"
                              required :error-messages="errors"
                              label="Название"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="region" rules="required">
                <v-autocomplete v-model="model.region"
                          :items="regions" @change="model.district = null"
                          item-value="id" item-text="title"
                          required :error-messages="errors"
                          label="Область"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="district" rules="required">
                <v-autocomplete v-model="model.district"
                          :items="getDistricts(model.region)"
                          item-value="id" item-text="title"
                          required :error-messages="errors"
                          label="Район / Город"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <v-autocomplete v-model="model.agent"
                              :items="agents" :loading="agentsLoading"
                              item-value="id" item-text="name"
                              label="Агент" hide-details clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="phone" rules="required|uzphone">
                  <v-text-field-integer v-model="model.phone"
                                        label="Мобильный"
                                        :properties="{
                                          prefix: '+998',
                                          required: true,
                                          'error-messages': errors,
                                          maxlength: 9,
                                          minlengh: 9,
                                        }"
                                        :options="{
                                        inputMask: '(##) ###-##-##',
                                        outputMask: '#########',
                                        empty: null,
                                        applyAfter: false,
                                        alphanumeric: true,
                                        lowerCase: false,
                                      }"
                  ></v-text-field-integer>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="phone_2" rules="uzphone">
                  <v-text-field-integer v-model="model.phone_2"
                                        label="Мобильный 2"
                                        :properties="{
                                          prefix: '+998',
                                          'error-messages': errors,
                                          maxlength: 9,
                                          minlengh: 9,
                                        }"
                                        :options="{
                                        inputMask: '(##) ###-##-##',
                                        outputMask: '#########',
                                        empty: null,
                                        applyAfter: false,
                                        alphanumeric: true,
                                        lowerCase: false,
                                      }"
                  ></v-text-field-integer>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="phone_3" rules="uzphone">
                  <v-text-field-integer v-model="model.phone_3"
                                        label="Мобильный 3"
                                        :properties="{
                                          prefix: '+998',
                                          'error-messages': errors,
                                          maxlength: 9,
                                          minlengh: 9,
                                        }"
                                        :options="{
                                        inputMask: '(##) ###-##-##',
                                        outputMask: '#########',
                                        empty: null,
                                        applyAfter: false,
                                        alphanumeric: true,
                                        lowerCase: false,
                                      }"
                  ></v-text-field-integer>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="phone_4" rules="uzphone">
                  <v-text-field-integer v-model="model.phone_4"
                                        label="Мобильный 4"
                                        :properties="{
                                          prefix: '+998',
                                          'error-messages': errors,
                                          maxlength: 9,
                                          minlengh: 9,
                                        }"
                                        :options="{
                                        inputMask: '(##) ###-##-##',
                                        outputMask: '#########',
                                        empty: null,
                                        applyAfter: false,
                                        alphanumeric: true,
                                        lowerCase: false,
                                      }"
                  ></v-text-field-integer>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="email" rules="max:256|email">
                <v-text-field v-model="model.email" required :error-messages="errors" label="E-mail" type="email"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="type" rules="required|max:256">
                <v-select v-model="model.group"
                          :items="groups"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Группа" :loading="groupsLoading"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="type" rules="required|max:256">
                <v-select v-model="model.counteragent_type"
                          :items="CounteragentTypes"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Тип"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="inn" rules="required|min:9">
                <v-text-field-integer v-model="model.inn"
                                      label="ИНН"
                                      :properties="{
                                          'error-messages': errors,
                                      }"
                                      :options="{
                                        inputMask: '### ### ###',
                                        outputMask: '#########',
                                        empty: null,
                                        applyAfter: false,
                                        alphanumeric: false,
                                        lowerCase: false,
                                      }"
                ></v-text-field-integer>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="bank_code" rules="min:5">
                <v-text-field-integer v-model="model.bank_code"
                                      label="МФО"
                                      :properties="{
                                          'error-messages': errors,
                                      }"
                                      :options="{
                                        inputMask: '######',
                                        outputMask: '######',
                                        empty: null,
                                        applyAfter: false,
                                        alphanumeric: false,
                                        lowerCase: false,
                                      }"
                ></v-text-field-integer>
              </validation-provider>
            </v-col>
            <v-col cols="4">
              <validation-provider v-slot="{errors}" name="bank_account" rules="min:20|max:20">
                <v-text-field-integer v-model="model.bank_account"
                                      label="Рассчетный счет"
                                      :properties="{
                                          'error-messages': errors,
                                      }"
                                      :options="{
                                        inputMask: '### ## ### # #### #######',
                                        outputMask: '####################',
                                        empty: null,
                                        applyAfter: false,
                                        alphanumeric: false,
                                        lowerCase: false,
                                      }"
                ></v-text-field-integer>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="client_type" rules="required">
                <v-select v-model="model.client_type"
                          :items="ClientTypes"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Тип клиента"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="address" rules="max:256">
                <v-text-field v-model="model.address" label="Адрес"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="comment_on_address" rules="max:256">
                <v-text-field v-model="model.comment_on_address" label="Комментарий к адресу"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="legal_address" rules="max:256">
                <v-text-field v-model="model.legal_address" label="Юридический адрес"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="comment_on_legal_address" rules="max:256">
                <v-text-field v-model="model.comment_on_legal_address" label="Комментарий к юридическому адресу"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="comment" rules="max:512">
                <v-textarea v-model="model.comment" label="Комментарий" rows="2"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          ><v-icon left>mdi-content-save</v-icon> {{model.id ? 'Сохранить' : 'Создать'}}</v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          ><v-icon left>mdi-cancel</v-icon> Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState, mapGetters} from 'vuex'

  export default {
    name: 'counteragent-form',

    computed: {
      ...mapState('Counteragent', [
        'fetching',
        'saving',
        'model',
        'CounteragentTypes',
        'ClientTypes',
      ]),
      ...mapState('Group', {
        groups: state => state.data?.results || [],
        groupsLoading: state => !!state.fetching,
      }),
      ...mapState('Location', {
        regions: state => state.regions?.results || [],
      }),
      ...mapGetters('Location', ['getDistricts']),

      ...mapGetters('User', ['getUsersByRole']),
      ...mapState('User', {
        agentsLoading: state => !!state.fetching
      }),
      agents() {
        return this.getUsersByRole('agent').map((item) => {
            return {
              id: item.id,
              name: this.$options.filters.full_name(item),
            }
          })
      },
    },

    methods: {
      cancel(reset) {
        reset()
        this.$emit('counteragent:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Counteragent/save')
          .then(() => {
            this.$emit('counteragent:form:saved')
          })
      },
    },
  }
</script>
