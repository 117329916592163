var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',{attrs:{"loading":!!_vm.fetching}},[_c('v-card-title',[_vm._v(_vm._s(_vm.model.id ? 'Изменить контрагент' : 'Создать контрагент'))]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"full_name","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Название"},model:{value:(_vm.model.full_name),callback:function ($$v) {_vm.$set(_vm.model, "full_name", $$v)},expression:"model.full_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.regions,"item-value":"id","item-text":"title","required":"","error-messages":errors,"label":"Область"},on:{"change":function($event){_vm.model.district = null}},model:{value:(_vm.model.region),callback:function ($$v) {_vm.$set(_vm.model, "region", $$v)},expression:"model.region"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"district","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.getDistricts(_vm.model.region),"item-value":"id","item-text":"title","required":"","error-messages":errors,"label":"Район / Город"},model:{value:(_vm.model.district),callback:function ($$v) {_vm.$set(_vm.model, "district", $$v)},expression:"model.district"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.agents,"loading":_vm.agentsLoading,"item-value":"id","item-text":"name","label":"Агент","hide-details":"","clearable":""},model:{value:(_vm.model.agent),callback:function ($$v) {_vm.$set(_vm.model, "agent", $$v)},expression:"model.agent"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required|uzphone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field-integer',{attrs:{"label":"Мобильный","properties":{
                                        prefix: '+998',
                                        required: true,
                                        'error-messages': errors,
                                        maxlength: 9,
                                        minlengh: 9,
                                      },"options":{
                                      inputMask: '(##) ###-##-##',
                                      outputMask: '#########',
                                      empty: null,
                                      applyAfter: false,
                                      alphanumeric: true,
                                      lowerCase: false,
                                    }},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"phone_2","rules":"uzphone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field-integer',{attrs:{"label":"Мобильный 2","properties":{
                                        prefix: '+998',
                                        'error-messages': errors,
                                        maxlength: 9,
                                        minlengh: 9,
                                      },"options":{
                                      inputMask: '(##) ###-##-##',
                                      outputMask: '#########',
                                      empty: null,
                                      applyAfter: false,
                                      alphanumeric: true,
                                      lowerCase: false,
                                    }},model:{value:(_vm.model.phone_2),callback:function ($$v) {_vm.$set(_vm.model, "phone_2", $$v)},expression:"model.phone_2"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"phone_3","rules":"uzphone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field-integer',{attrs:{"label":"Мобильный 3","properties":{
                                        prefix: '+998',
                                        'error-messages': errors,
                                        maxlength: 9,
                                        minlengh: 9,
                                      },"options":{
                                      inputMask: '(##) ###-##-##',
                                      outputMask: '#########',
                                      empty: null,
                                      applyAfter: false,
                                      alphanumeric: true,
                                      lowerCase: false,
                                    }},model:{value:(_vm.model.phone_3),callback:function ($$v) {_vm.$set(_vm.model, "phone_3", $$v)},expression:"model.phone_3"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"phone_4","rules":"uzphone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field-integer',{attrs:{"label":"Мобильный 4","properties":{
                                        prefix: '+998',
                                        'error-messages': errors,
                                        maxlength: 9,
                                        minlengh: 9,
                                      },"options":{
                                      inputMask: '(##) ###-##-##',
                                      outputMask: '#########',
                                      empty: null,
                                      applyAfter: false,
                                      alphanumeric: true,
                                      lowerCase: false,
                                    }},model:{value:(_vm.model.phone_4),callback:function ($$v) {_vm.$set(_vm.model, "phone_4", $$v)},expression:"model.phone_4"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"email","rules":"max:256|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"E-mail","type":"email"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.groups,"item-value":"id","item-text":"name","required":"","error-messages":errors,"label":"Группа","loading":_vm.groupsLoading},model:{value:(_vm.model.group),callback:function ($$v) {_vm.$set(_vm.model, "group", $$v)},expression:"model.group"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.CounteragentTypes,"item-value":"id","item-text":"name","required":"","error-messages":errors,"label":"Тип"},model:{value:(_vm.model.counteragent_type),callback:function ($$v) {_vm.$set(_vm.model, "counteragent_type", $$v)},expression:"model.counteragent_type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"inn","rules":"required|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field-integer',{attrs:{"label":"ИНН","properties":{
                                        'error-messages': errors,
                                    },"options":{
                                      inputMask: '### ### ###',
                                      outputMask: '#########',
                                      empty: null,
                                      applyAfter: false,
                                      alphanumeric: false,
                                      lowerCase: false,
                                    }},model:{value:(_vm.model.inn),callback:function ($$v) {_vm.$set(_vm.model, "inn", $$v)},expression:"model.inn"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"bank_code","rules":"min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field-integer',{attrs:{"label":"МФО","properties":{
                                        'error-messages': errors,
                                    },"options":{
                                      inputMask: '######',
                                      outputMask: '######',
                                      empty: null,
                                      applyAfter: false,
                                      alphanumeric: false,
                                      lowerCase: false,
                                    }},model:{value:(_vm.model.bank_code),callback:function ($$v) {_vm.$set(_vm.model, "bank_code", $$v)},expression:"model.bank_code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"bank_account","rules":"min:20|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field-integer',{attrs:{"label":"Рассчетный счет","properties":{
                                        'error-messages': errors,
                                    },"options":{
                                      inputMask: '### ## ### # #### #######',
                                      outputMask: '####################',
                                      empty: null,
                                      applyAfter: false,
                                      alphanumeric: false,
                                      lowerCase: false,
                                    }},model:{value:(_vm.model.bank_account),callback:function ($$v) {_vm.$set(_vm.model, "bank_account", $$v)},expression:"model.bank_account"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"client_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.ClientTypes,"item-value":"id","item-text":"name","required":"","error-messages":errors,"label":"Тип клиента"},model:{value:(_vm.model.client_type),callback:function ($$v) {_vm.$set(_vm.model, "client_type", $$v)},expression:"model.client_type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"address","rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Адрес"},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"comment_on_address","rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Комментарий к адресу"},model:{value:(_vm.model.comment_on_address),callback:function ($$v) {_vm.$set(_vm.model, "comment_on_address", $$v)},expression:"model.comment_on_address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"legal_address","rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Юридический адрес"},model:{value:(_vm.model.legal_address),callback:function ($$v) {_vm.$set(_vm.model, "legal_address", $$v)},expression:"model.legal_address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"comment_on_legal_address","rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Комментарий к юридическому адресу"},model:{value:(_vm.model.comment_on_legal_address),callback:function ($$v) {_vm.$set(_vm.model, "comment_on_legal_address", $$v)},expression:"model.comment_on_legal_address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"comment","rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Комментарий","rows":"2"},model:{value:(_vm.model.comment),callback:function ($$v) {_vm.$set(_vm.model, "comment", $$v)},expression:"model.comment"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-space-between pb-4 px-4"},[_c('v-btn',{attrs:{"loading":!!_vm.saving,"disabled":!!_vm.saving || invalid,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.model.id ? 'Сохранить' : 'Создать'))],1),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Отмена")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }