var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{ref:"filtersContainer",staticClass:"mb-1"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"text-h5",staticStyle:{"vertical-align":"middle"}},[_vm._v("Список приёмок")]),(_vm.userService.can('priyomka_add_button'))?[_c('v-btn',{staticClass:"ml-3",staticStyle:{"vertical-align":"middle"},attrs:{"rounded":"","color":"primary","small":""},on:{"click":function($event){return _vm.showForm()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1),_c('v-dialog',{attrs:{"width":"95vw","persistent":""},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[(_vm.formDialog)?_c('transfer-form',{on:{"transfer:form:saved":_vm.closeForm,"transfer:form:cancel":_vm.closeForm}}):_vm._e()],1)]:_vm._e()],2),_c('v-col',{staticClass:"text-right font-weight-bold pb-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"purple--text text--darken-3"},[_vm._v("Всего в суммах: "+_vm._s(_vm._f("money")(_vm.totals.uzs_summa||0)))]),_c('span',{staticClass:"indigo--text text--darken-2 ml-3"},[_vm._v("Всего в валюте: "+_vm._s(_vm._f("money")(_vm.totals.usd_summa,'usd')))])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{ref:"reg_number_filter",attrs:{"disabled":!!_vm.fetching,"label":"Номер регистрации","prepend-inner-icon":"mdi-identifier","clearable":"","hide-details":""},model:{value:(_vm.filter.reg_number),callback:function ($$v) {_vm.$set(_vm.filter, "reg_number", $$v)},expression:"filter.reg_number"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.fromDateFormatted,"label":"с","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"disabled":!!_vm.fetching},model:{value:(_vm.filter.reg_date_after),callback:function ($$v) {_vm.$set(_vm.filter, "reg_date_after", $$v)},expression:"filter.reg_date_after"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.toDateFormatted,"label":"по","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{attrs:{"disabled":!!_vm.fetching},model:{value:(_vm.filter.reg_date_before),callback:function ($$v) {_vm.$set(_vm.filter, "reg_date_before", $$v)},expression:"filter.reg_date_before"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.counteragents,"item-value":"id","item-text":"full_name","prepend-inner-icon":"mdi-account-group","label":"Контрагент","loading":!!_vm.counteragentsLoading,"disabled":!!_vm.counteragentsLoading,"clearable":"","hide-details":""},model:{value:(_vm.filter.counteragent),callback:function ($$v) {_vm.$set(_vm.filter, "counteragent", $$v)},expression:"filter.counteragent"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.nonSortableHeaders,"items":_vm.transfers,"loading":!!_vm.fetching,"items-per-page":_vm.filter.limit,"height":_vm.dataTableHeight,"disable-items-per-page":"","hide-default-footer":"","fixed-header":"","no-data-text":"Нет данных","loading-text":"Данные загружаются"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.filter.limit * (_vm.filter.page - 1) + index + 1))]),_c('td',[_vm._v(_vm._s(item.reg_number))]),_c('td',[_vm._v(_vm._s(_vm._f("date")(item.reg_date)))]),_c('td',[_vm._v(_vm._s(item.counteragent))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")(item.total,item.currency)))]),_c('td',[_vm._v(_vm._s(item.warehouse.name))]),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.showDetails(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-eye")]),_vm._v(" Показать ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":"","depressed":"","color":"primary","dark":""},on:{"click":function($event){return _vm.showForm(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":"","depressed":"","color":"error","loading":_vm.deleting[item.id]},on:{"click":function($event){return _vm.askRemove(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-delete")])],1)],1)])]}}])}),_c('pagination',{ref:"paginationContainer",attrs:{"limit":_vm.limit,"page":_vm.page,"total-pages":_vm.totalPages},on:{"update:limit":function($event){_vm.limit=$event},"update:page":function($event){_vm.page=$event}}}),_c('v-dialog',{attrs:{"width":"95vw","persistent":""},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[_c('transfer-details',{on:{"dialog:close":_vm.closeDetails,"dialog:edit":_vm.editForm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }