import {mapGetters, mapState} from 'vuex'

export default {
  computed: {
    ...mapGetters('CurrencyRate', ['getCurrency']),
    ...mapState('CurrencyRate', {
      currencies: state => state.Currencies,
      currentExchangeRate: 'current',
    }),

    pricePrecision() {
      return this.getCurrency(this[this.$options.currencyModel || 'model'].currency).precision
    },

    priceSuffix() {
      return this.getCurrency(this[this.$options.currencyModel || 'model'].currency).format
    },

    priceField() {
      return this.$options.currencyFields[this[this.$options.currencyModel || 'model'].currency] === 'usd' ? 'sell_price_usd' : 'sell_price'
    },

    isCurrencyUsd() {
      return this[this.$options.currencyModel || 'model'].currency === 'usd'
    },
  },
}
