import Vue from 'vue'
import {Resource} from 'vue-resource'

import ut from '@/utils'
import moment from 'moment'

import {SERVER_DATE_FORMAT} from '@/filters'
import {Currencies} from './currency-rates'
import {PaymentTypes} from './payment'

const StartingPoint = {
  id: null,
  counteragent: null,
  reg_date: moment().format(SERVER_DATE_FORMAT),
  type: PaymentTypes[0].id,
  currency: Currencies[0].id,
  amount: '',
}

const StartingPointResource = new Resource('/', {}, {
  create: {
    method: 'POST',
    url: 'counteragent/starting-point/create/',
  },
  remove: {
    method: 'DELETE',
    url: 'counteragent/starting-point/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    model: ut.deepClone(StartingPoint),
    saving: false,
    deleting: {},
  },

  mutations: {
    setModel(state, data) {
      state.model = {...ut.deepClone(StartingPoint), ...data}

      if (state.model.counteragent && ('object' === typeof state.model.counteragent)) {
        state.model.counteragent_full_name = state.model.counteragent.full_name
        state.model.counteragent = state.model.counteragent.id
      }
    },
  },

  actions: {
    save({state, commit}) {
      state.saving = true
      return StartingPointResource
                  .create(ut.objFilter(state.model))
                  .then(({body}) => {
                      commit('setModel', body)
                  })
                  .finally(() => state.saving = false)
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return StartingPointResource.remove({id})
                .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
