import {UserService} from '@/services/user'
import store from "@/store";

export const RouteService = {
  menuList() {
    let isAdmin = UserService.hasRole(['admin', 'boss'])

    let menus = []

    if (UserService.can('contragents_module')) {
      menus.push({icon: 'mdi-account-group', title: 'Контрагенты', routeName: 'counteragent.list'})
    }

    if (isAdmin || UserService.can('purchase_block')) {
      let menu = {icon: 'mdi-inbox-full', title: 'Закупки', items: [
          {icon: 'mdi-human-dolly', title: 'Приёмки', routeName: 'transfer.list',},
          {icon: 'mdi-warehouse', title: 'Склады', routeName: 'warehouse.list'},
      ]}

      if (isAdmin || UserService.can('transport_expenditure_block')) {
        menu.items.push({icon: 'mdi-gas-station', title: 'Транспортные расходы', routeName: 'transport-expense.list'})
      }

      if (isAdmin || UserService.can('universal_expenditure_block')) {
        menu.items.push({icon: 'mdi-script-outline', title: 'Универсальные расходы', routeName: 'universal-expense.list'})
      }

      menus.push(menu)
    }

    if (isAdmin || UserService.can('production_block')) {
      let menu = {icon: 'mdi-book-open-variant', title: 'Производство', items: []}

      if (UserService.can('product_document_block')) {
        menu.items.push({icon: 'mdi-text-box-check-outline', title: 'Документы продукта', routeName: 'product-collection.list'})
      }

      if (UserService.can('products_block')) {
        menu.items.push({icon: 'mdi-file-certificate', title: 'Продукты', routeName: 'product-item.list'})
      }
      if (UserService.can('product_types_block')) {
        menu.items.push({icon: 'mdi-file-certificate-outline', title: 'Типы продукта', routeName: 'product.list'})
      }
      if (UserService.can('daily_volumes_block')) {
        menu.items.push({icon: 'mdi-hammer-wrench', title: 'Дневные объемы работ', routeName: 'job-volumes.list'})
      }
      if (UserService.can('retsept_block')) {
        menu.items.push({icon: 'mdi-sitemap', title: 'Рецепт', routeName: 'recipe.list'},)
      }
      if (UserService.can('raw_material_block')) {
        menu.items.push({icon: 'mdi-database-check', title: 'Сырье', routeName: 'material.list'})
      }
      if (UserService.can('measurements_block')) {
        menu.items.push({icon: 'mdi-weight-kilogram', title: 'Ед измерения', routeName: 'measurement.list'})
      }

      menus.push(menu)
    }

    if (isAdmin || UserService.can('sale_block')) {
      let menu = {icon: 'mdi-cart-arrow-up', title: 'Продажа', items: []}
      menu.items.push({icon: 'mdi-cart-check', title: 'Документы продаж', routeName: 'consignment.list'})
      menu.items.push({icon: 'mdi-cart-variant', title: 'Продажи', routeName: 'consignment-item.list'})

      if (isAdmin || UserService.can('return_block')) {
        menu.items.push({icon: 'mdi-cart-remove', title: 'Возврат', routeName: 'consignment-returns.list'})
      }

      menus.push(menu)
    }

    if (isAdmin || UserService.can('finance_block')) {
      let menu = {icon: 'mdi-cash-multiple', title: 'Финансы', items: []}

      if (UserService.can('kassa_block')) {
        menu.items.push({icon: 'mdi-cash-fast', title: 'Касса', routeName: 'payment.list'})
      }

      // if (UserService.can('expenditures_block')) {
      //   menu.items.push({icon: 'mdi-cash-refund', title: 'Затраты', routeName: 'expenses.list'})
      // }

      if (UserService.can('salary_month_statement_block')) {
        menu.items.push({icon: 'mdi-account-cash-outline', title: 'Зарплатные ведемости', routeName: 'salary-statement.list'})
      }

      if (UserService.can('currency_rate_block')) {
        menu.items.push({icon: 'mdi-currency-usd', title: 'Курс валюты', routeName: 'currency-rates.list'})
      }

      if (UserService.can('report_income_expense_block')) {
        menu.items.push({icon: 'mdi-swap-horizontal', title: 'Операции', routeName: 'warehouse.report.operations'})
      }

      if (UserService.can('currency_rate_block')) {
        menu.items.push({icon: 'mdi-bank-transfer', title: 'Конверсия', routeName: 'currency-rates.exchange'})
      }

      menus.push(menu)
    }

    if (isAdmin || UserService.can('organization_block')) {
      let menu = {icon: 'mdi-domain', title: 'Организация', items: []}

      if (UserService.can('owners_block')) {
        menu.items.push({icon: 'mdi-account-star', title: 'Учредитель', routeName: 'owner.list'})
      }

      if (UserService.can('staff_block')) {
        menu.items.push({icon: 'mdi-account-tie', title: 'Сотрудники', routeName: 'staff.list'})
      }

      if (UserService.can('transport_block')) {
        menu.items.push({icon: 'mdi-car', title: 'Транспорт', routeName: 'transport.list'})
      }

      if (UserService.can('counteragent_groups_block')) {
        menu.items.push({icon: 'mdi-account-group', title: 'Группы контрагентов', routeName: 'group.list'})
      }

      menus.push(menu)
    }

    if (isAdmin || UserService.can('reports_block')) {
      let menu = {icon: 'mdi-chart-bar', title: 'Отчеты', items: []}

      if (UserService.hasRole('boss')) {
        menu.items.push({icon: 'mdi-finance', title: 'Показатели', routeName: 'warehouse.report.dashboard'})
      }

      if (UserService.can('report_production_block')) {
        menu.items.push({icon: 'mdi-table', title: 'Производство', routeName: 'warehouse.report.product-collection'})
        menu.items.push({icon: 'mdi-book-open-outline', title: 'Промеж отчет производства', routeName: 'warehouse.report.intermediate-production'})
        menu.items.push({icon: 'mdi-cash-multiple', title: 'Промеж отчет продаж', routeName: 'warehouse.report.intermediate-consignment'})
      }

      if (UserService.can('report_product_remain_block')) {
        menu.items.push({icon: 'mdi-poll', title: 'Остатки продуктов', routeName: 'warehouse.report.product'})
        menu.items.push({icon: 'mdi-poll', title: 'Отчет по производству и продажам', routeName: 'warehouse.report.producement'})
      }

      if (UserService.can('report_rawmaterial_remain_block')) {
        menu.items.push({icon: 'mdi-chart-gantt', title: 'Остатки материалов', routeName: 'warehouse.report.material'})
      }

      if (UserService.can('report_loaners_block')) {
        menu.items.push({icon: 'mdi-card-account-details', title: 'Должники', routeName: 'warehouse.report.counteragent'})
      }

      if (UserService.can('report_income_expense_block')) {
        menu.items.push({icon: 'mdi-swap-horizontal', title: 'Доходы и расходы', routeName: 'warehouse.report.expenditures-by-source'})
      }

      if (UserService.can('report_monthly_block')) {
        menu.items.push({icon: 'mdi-calendar-month', title: 'Производство по месяцам', routeName: 'warehouse.report.products-by-months'})
        menu.items.push({icon: 'mdi-cart-arrow-up', title: 'Продажи по месяцам', routeName: 'warehouse.report.consignments-by-months'})
      }

      if (UserService.can('report_oborot_block')) {
        menu.items.push({icon: 'mdi-chart-timeline-variant-shimmer', title: 'Оборот', routeName: 'warehouse.report.turnover-by-months'})
      }

      menus.push(menu)
    }

    if (isAdmin || UserService.can('settings_block')) {
      menus.push({icon: 'mdi-cog', title: 'Настройки', items: [
          {icon: 'mdi-shield-account', title: 'Пользователи', routeName: 'admin.users'},
        ]})
    }

    return menus
  },

  navigate(route, from, next) {
    let check = () => {
      if (UserService.isGuest()) {
        if (route.meta.guest) {
          return next()
        } else {
          return next({name: 'login'})
        }
      } else {
        if (route.name === 'login') {
          return next(RouteService.redirectLogged())
        }

        if (route.name === 'page.unauthorized' || UserService.canNavigate(route)) {
          return next()
        } else {
          return next({name: 'page.unauthorized'})
        }
      }
    }

    store.state.initing.then(check)
  },

  redirectLogged() {
    return {name: (store.getters['User/getRole'](UserService.getUserRole()) || {home: 'login'}).home}
  }
}
