export const HistoryService = {
  getTitle(item) {
    let obj = (item.source_object || {id: '', reg_number: ''})

    switch (item.source_name) {
      case 'priyomkadocument':
        return `Приемка № ${obj.id}`
      case 'payment':
        return `Платеж № ${obj.id}`
      case 'consignmentdoc':
        return `Продажа № ${obj.id}`
      case 'returnproductdoc':
        return `Возврат № ${obj.id}`
      case 'counteragentcalculationstartingpoint':
        return 'Коррекция баланса'
      case 'transportexpenditure':
        return 'Транспортные расходы #' + item.id
      case 'universalexpenditure':
        return 'Универсиальные расходы #' + item.id
      default:
        return 'Операция'
    }
  },

  getRoute(item) {
    switch (item.source_name) {
      case 'priyomkadocument':
        return {name: 'transfer.detail', params: {id: (item.source_object || {id: ''}).id}}
      case 'payment':
        return {name: 'payment.detail', params: {id: (item.source_object || {id: ''}).id}}
      case 'consignmentdoc':
        return {name: 'consignment.detail', params: {id: (item.source_object || {id: ''}).id}}
      default:
        return {path: '/'}
    }
  },
}
