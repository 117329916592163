import ut from '@/utils'
import {Resource} from 'vue-resource'
import {PaymentForms} from './payment'
import {Currencies} from './currency-rates'

const Expense = {
  id: null,
  reg_number: '',
  reg_date: '',
  type: '',
  cash_type: PaymentForms[0].id,
  currency: Currencies[0].id,
  total: '',
  comments: '',

  source: '',
  source_object_info: {},
}

const ExpenseResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'payment/expenses/list/',
  }
})

export default {
  namespaced: true,

  state: {
    data: null,
    fetching: false,
    model: ut.deepClone(Expense),
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(Expense), ...data}
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = ExpenseResource
                            .list({...ut.objFilter(filters), offset: filters.limit * (filters.page - 1)})
                            .then((response) => {
                              commit('setItems', response.body)
                            })
                            .finally(() => state.fetching = false)
      }

      return state.fetching
    },
  },
}
