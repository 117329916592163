<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{model.id ? 'Изменить сырье' : 'Создать сырье'}}</v-card-title>
        <v-card-text>
          <v-row>
<!--            <v-col cols="2">-->
<!--              <validation-provider v-slot="{errors}" name="code" rules="required|integer">-->
<!--                <v-text-field v-model="model.code"-->
<!--                              required :error-messages="errors"-->
<!--                              label="Код"-->
<!--                ></v-text-field>-->
<!--              </validation-provider>-->
<!--            </v-col>-->
            <v-col cols="9">
              <validation-provider v-slot="{errors}" name="name" rules="required|max:256">
                <v-text-field v-model="model.name"
                              required :error-messages="errors"
                              label="Название"
                ></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="measurement" rules="required|integer">
                <v-select v-model="model.measurement"
                          :items="measurements"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Ед измерения"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="buy_price" rules="required|double">
                <v-text-field v-model="model.buy_price"
                              required :error-messages="errors"
                              label="Цена в суммах" suffix="сум"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="buy_price" rules="required|double">
                <v-text-field v-model="model.buy_price_usd"
                              required :error-messages="errors"
                              label="Цена в долларах" suffix="$"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="min_notified_amount" rules="double">
                <v-text-field v-model="model.min_notified_amount"
                              required :error-messages="errors"
                              label="Оповестить если остаток ниже ..."
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          ><v-icon left>mdi-content-save</v-icon> {{model.id ? 'Сохранить' : 'Создать'}}</v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          ><v-icon left>mdi-cancel</v-icon> Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'material-form',

    computed: {
      ...mapState('Material', ['saving', 'model']),

      ...mapState('Measurement', {
        measurements: state => state.data?.results || []
      }),
    },

    methods: {
      cancel(reset) {
        reset()
        this.$emit('material:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Material/save')
          .then(() => {
            this.$emit('material:form:saved')
          })
      },
    },
  }
</script>
