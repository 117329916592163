<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{ 'Установить начальный баланс' }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="reg_date" rules="required|max:256">
                <v-menu ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        max-width="290px" min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="model.reg_date|date" label="Дата" name="reg_date"
                                  prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="model.reg_date" @input="dateMenu = false"
                                 :allowed-dates="allowedRegDates"
                  ></v-date-picker>
                </v-menu>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="currency" rules="required">
                <v-select v-model="model.currency"
                          required :error-messages="errors"
                          :items="currencies" label="Валюта" name="currency"
                          item-value="id" item-text="title" hide-details
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="type" rules="required">
                <v-select v-model="model.type"
                          required :error-messages="errors"
                          label="Тип транзакции"
                          :items="paymentTypes"
                          item-value="id" item-text="altName"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="amount" rules="required|double">
                <v-text-field v-model="model.amount"
                              required :error-messages="errors" hide-details
                              label="Цена" :suffix="priceSuffix"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 mt-2 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          >
            <v-icon left>mdi-content-save</v-icon>
            Сохранить
          </v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          >
            <v-icon left>mdi-cancel</v-icon>
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import moment from 'moment'

export default {
  name: 'starting-point-form',

  data() {
    return {
      dateMenu: false,
      loading: true,
    }
  },

  computed: {
    ...mapState('StartingPoint', ['saving', 'model']),

    ...mapState('CurrencyRate', {
      currencies: state => state.Currencies,
    }),

    ...mapState('Payment', {
      paymentTypes: state => state.PaymentTypes,
    }),

    ...mapGetters('CurrencyRate', ['getCurrency']),

    priceSuffix() {
      return this.getCurrency(this.model.currency).format
    }
  },

  methods: {
    allowedRegDates(val) {
      return moment().isAfter(moment(val))
    },

    cancel(reset) {
      reset()
      this.$emit('starting-point:form:cancel')
    },

    save() {
      this.$store
        .dispatch('StartingPoint/save')
        .then(() => {
          this.$emit('starting-point:form:saved')
        })
    },
  },
}
</script>

<style>
.inner-elevation-0 .v-input__slot {
  box-shadow: none !important;
}
</style>
