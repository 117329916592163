<template>
  <v-card>
    <template #progress>
      <v-progress-linear color="primary" indeterminate></v-progress-linear>
    </template>

    <v-card-title>
      Детали универсального расхода #{{model.id}} от {{model.reg_date|date}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div><b>Дата регистрации:</b> {{model.reg_date|date}}</div>
          <div><b>Тип:</b> {{getType(model.type).label}}</div>
          <div><b>Контрагент:</b> {{(model.counteragent ? getCounteragent(model.counteragent) : {}).full_name}}</div>
          <div><b>Сумма:</b> {{model.amount|money}}</div>
          <div class="mt-3"><b>Комментарий:</b><br> {{model.description}}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers, mapState as defaultMapState, mapGetters as defaultMapGetters} from 'vuex'
  const {mapState, mapGetters} = createNamespacedHelpers('UniversalExpense')

  export default {
    name: 'universal-expense-details',

    computed: {
      ...mapState(['model']),
      ...mapGetters(['getType']),

      ...defaultMapState('Counteragent', {
        counteragents: state => (state.data?.results || []),
        counteragentsLoading: state => !!state.fetching,
      }),
      ...defaultMapGetters('Counteragent', ['getCounteragent']),

    },
  }
</script>
