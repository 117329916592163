<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card :loading="!!fetching">
        <v-card-title>{{model.id ? 'Изменить документ продукта' : 'Создать документ продукта'}}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-menu ref="transferDate"
                      v-model="regDateMenu"
                      :disabled="regDateIsBlocked"
                      :close-on-content-click="false"
                      max-width="290px" min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="model.reg_date|date" label="Дата"
                                prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                                :disabled="regDateIsBlocked"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="model.reg_date" @input="regDateMenu = false"
                                :disabled="regDateIsBlocked"
                                :allowed-dates="allowedRegDates"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-show="false" cols="12">
              <validation-provider v-slot="{errors}" name="recipe_doc" rules="required|integer">
                <v-select v-model="model.from_warehouse"
                          :items="materialWarehouses"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Склад (сырье)"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col v-show="false" cols="12">
              <validation-provider v-slot="{errors}" name="recipe_doc" rules="required|integer">
                <v-select v-model="model.warehouse"
                          :items="productWarehouses"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Склад (продукт)"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col v-show="false" cols="12">
              <validation-provider v-slot="{errors}" name="description" rules="max:1024">
                <v-textarea v-model="model.description" rows="3"
                            :error-messages="errors"
                            label="Описание"
                ></v-textarea>
              </validation-provider>
            </v-col>

            <v-col cols="12">
              <p class="my-0">Типы продуктов</p>
            </v-col>
            <template v-for="(item, index) in collectionItems">
              <v-col cols="10">
                <validation-provider v-slot="{errors}" :name="`products.${index}`" rules="required|integer">
                  <v-autocomplete v-model="item.product_type"
                                  @change="item.id = null"
                                  :items="productTypes" item-text="name" item-value="id"
                                  required :error-messages="errors"
                                  :label="item.id ? 'Продукт' : 'Тип продукта'" dense
                                  :disabled="!!item.id"
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="2">
                <validation-provider v-slot="{errors}" :name="`amount.${index}`" rules="required|integer|positive">
                  <v-text-field v-model="item.amount"
                                required :error-messages="errors"
                                label="Кол-во" dense type="number"
                                :append-outer-icon="collectionItems.length > 1 ? 'mdi-close' : ''"
                                @click:append-outer="collectionItems.length > 1 ? removeCollectionItem(index) : ()=>{}"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </template>
            <v-col cols="12">
              <v-btn @click="createCollectionItem" color="primary" small><v-icon small left>mdi-plus</v-icon> Добавить продукт</v-btn>
            </v-col>
            <v-col v-if="deletingItems.length" cols="12" class="error--text">
              <div>Удаляемые записи: {{deletingItems.length}}</div>
              <div v-for="deletingItem in deletingItems" :key="deletingItem.id">
                {{deletingItem.product_type_name}} - кол-во: {{deletingItem.amount}}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          ><v-icon left>mdi-content-save</v-icon> {{model.id ? 'Сохранить' : 'Создать'}}</v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          ><v-icon left>mdi-cancel</v-icon> Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState, mapMutations} from 'vuex'
  import moment from "moment";

  export default {
    name: 'product-collection-form',

    props: {
      startingPoint: Boolean,
    },

    data() {
      return {
        regDateMenu: false,
      }
    },

    computed: {
      ...mapState('ProductCollection', ['saving', 'model', 'collectionItems', 'fetching', 'deletingItems']),

      ...mapState('Product', {
        productTypes: state => state.data?.results || []
      }),

      ...mapState('Warehouse', {
        materialWarehouses: state => (state.data.results || []).filter(w => w.type === 'raw_material'),
        productWarehouses: state => (state.data.results || []).filter(w => w.type === 'product'),
      }),

      regDateIsBlocked() {
        return !!(this.model.id || !this.userService.can('can_product_all_date'))
      },
    },

    methods: {
      allowedRegDates(val) {
        return moment().isAfter(moment(val))
      },

      ...mapMutations('ProductCollection', [
        'createCollectionItem',
        'removeCollectionItem',
        'updateModel',
      ]),

      cancel(reset) {
        reset()
        this.$emit('product-collection:form:cancel')
      },

      save() {
        this.$store
          .dispatch('ProductCollection/save', this.startingPoint)
          .then(() => {
            this.$emit('product-collection:form:saved')
          })
      },
    },

    mounted() {
      if (!this.fetching && !this.model.id) {
        if (this.materialWarehouses.length) {
          this.updateModel({from_warehouse: this.materialWarehouses[0].id})
        }

        if (this.productWarehouses.length) {
          this.updateModel({warehouse: this.productWarehouses[0].id})
        }
      }
    }
  }
</script>
