import Vue from 'vue'
import VueRouter from 'vue-router'

import {RouteService} from '@/services'

import NotFound from '@/pages/404'
import Unauthorized from '@/pages/403'
import login from '@/modules/auth/login'

import ContractModule from '@/modules/contract/list'
import CounteragentModule from '@/modules/counteragent/list'
import MaterialModel from '@/modules/material/list'
import MeasurementModule from '@/modules/measurement/list'
import ProductModule from '@/modules/product/list'
import ProductItemModule from '@/modules/product-item/list'
import ProductCollectionModule from '@/modules/product-collection/list'
import RecipeModule from '@/modules/recipe/list'
import TransferModule from '@/modules/transfer/list'
import TransportExpenseModule from '@/modules/transport-expense/list'
import UniversalExpenseModule from '@/modules/universal-expense/list'
import WarehouseModule from '@/modules/warehouse/list'

const ConsignmentModule = () => import(/* webpackChunkName: 'consignment' */ '@/modules/consignment/list')
const ConsignmentReturnModule = () => import(/* webpackChunkName: 'consignment' */ '@/modules/consignment/return/list')
const ConsignmentItemModule = () => import(/* webpackChunkName: 'consignment' */ '@/modules/consignment/item-list')

const PaymentModule = () => import(/* webpackChunkName: 'payment' */ '@/modules/payment/list')
const ExpensesModule = () => import(/* webpackChunkName: 'payment' */ '@/modules/expense/list')
const CurrencyRatesModule = () => import(/* webpackChunkName: 'payment' */ '@/modules/currency-rates/list')
const CurrencyExchangeModule = () => import(/* webpackChunkName: 'payment' */ '@/modules/currency-rates/exchange')
const SalaryStatementModule = () => import(/* webpackChunkName: 'payment' */ '@/modules/salary-statements/list')
const JobVolumesModule = () => import(/* webpackChunkName: 'payment' */ '@/modules/salary-statements/job-volume-list')

const StaffModule = () => import(/* webpackChunkName: 'staff' */ '@/modules/staff/list')
const GroupModule = () => import(/* webpackChunkName: 'staff' */ '@/modules/group/list')
const OwnerModule = () => import(/* webpackChunkName: 'staff' */ '@/modules/owner/list')
const TransportModule = () => import(/* webpackChunkName: 'transport' */ '@/modules/transport/list')

const ProductCollectionReport = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/product-collection')
const IntermediateProduction = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/intermediate-production')
const IntermediateConsignment = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/intermediate-consignment')
const ProductReport = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/product')
const ProducementReport = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/producement')
const MaterialReport = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/material')
const CounteragentReport = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/counteragents')
const OperationsReport = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/operations')
const ProductsByMonthsReport = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/products-by-months')
const ConsignmentsByMonthsReport = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/consignments-by-months')
const TurnoverByMonthsReport = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/turnover-by-months')
const DashboardReport = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/dashboard')
const ExpendituresBySource = () => import(/* webpackChunkName: 'reports' */ '@/modules/report/expenditures-by-source')

const profile = () => import(/* webpackChunkName: 'app-profile' */ '@/modules/auth/profile')

const UserModule = () => import(/* webpackChunkName: 'app-profile' */ '@/modules/user/list')

const strats = Vue.config.optionMergeStrategies
strats.beforeRouteEnter = strats.beforeRouteLeave = strats.beforeRouteUpdate = strats.created
Vue.use(VueRouter)

const routes = [
  {
    path: '/profile',
    name: 'profile',
    component: profile,
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      guest: true,
    },
  },
  {
    path: '/warehouses',
    name: 'warehouse.list',
    component: WarehouseModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['purchase_block'],
    },
  },
  {
    path: '/measurement',
    name: 'measurement.list',
    component: MeasurementModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['measurements_block'],
    },
  },
  {
    path: '/materials',
    name: 'material.list',
    component: MaterialModel,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['raw_material_block'],
    },
  },
  {
    path: '/recipes',
    name: 'recipe.list',
    component: RecipeModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['retsept_block'],
    },
  },
  {
    path: '/products',
    name: 'product.list',
    component: ProductModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['product_types_block'],
    },
  },
  {
    path: '/product-items',
    name: 'product-item.list',
    component: ProductItemModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['products_block'],
    },
  },
  {
    path: '/product-collection',
    name: 'product-collection.list',
    component: ProductCollectionModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['product_document_block'],
    },
  },
  {path: '/', redirect: _ => {
    return RouteService.redirectLogged()
  }},
  {
    path: '/counteragents',
    name: 'counteragent.list',
    component: CounteragentModule,
    props: true,
    meta: {
      roles: ['agent', 'admin', 'boss'],
      permissions: ['contragents_module'],
    },
  },
  {
    path: '/contracts',
    name: 'contract.list',
    component: ContractModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
    },
  },
  {
    path: '/transfers',
    name: 'transfer.list',
    component: TransferModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['purchase_block',]
    },
    children: [
      {
        path: ':id',
        name: 'transfer.detail',
        component: TransferModule,
        meta: {
          roles: ['admin', 'boss'],
          permissions: ['purchase_block'],
        },
      },
    ]
  },
  {
    path: '/transport-expenses',
    name: 'transport-expense.list',
    component: TransportExpenseModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['purchase_block'],
    },
  },
  {
    path: '/universal-expenses',
    name: 'universal-expense.list',
    component: UniversalExpenseModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['purchase_block'],
    },
  },
  {
    path: '/consignments',
    name: 'consignment.list',
    component: ConsignmentModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['sale_block'],
    },
    children: [
      {
        path: ':id',
        name: 'consignment.detail',
        component: ConsignmentModule,
        meta: {
          roles: ['admin', 'boss'],
          permissions: ['sale_block'],
        },
      },
    ]
  },
  {
    path: '/consignment-items',
    name: 'consignment-item.list',
    component: ConsignmentItemModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['sale_block'],
    },
  },
  {
    path: '/consignment-returns',
    name: 'consignment-returns.list',
    component: ConsignmentReturnModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['return_block'],
    },
  },
  {
    path: '/payments',
    name: 'payment.list',
    component: PaymentModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['kassa_block'],
    },
    children: [
      {
        path: ':id',
        name: 'payment.detail',
        component: PaymentModule,
        meta: {
          roles: ['admin', 'boss'],
          permissions: ['kassa_block'],
        },
      },
    ]
  },
  {
    path: '/expenses',
    name: 'expenses.list',
    component: ExpensesModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['expenditures_block'],
    },
  },
  {
    path: '/currency-rates',
    name: 'currency-rates.list',
    component: CurrencyRatesModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['currency_rate_block'],
    },
  },
  {
    path: '/currency-exchange',
    name: 'currency-rates.exchange',
    component: CurrencyExchangeModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['currency_rate_block'],
    },
  },
  {
    path: '/staff',
    name: 'staff.list',
    component: StaffModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['staff_block'],
    },
  },
  {
    path: '/salary-statements',
    name: 'salary-statement.list',
    component: SalaryStatementModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['kassa_block'],
    },
  },
  {
    path: '/job-volumes',
    name: 'job-volumes.list',
    component: JobVolumesModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['daily_volumes_block'],
    },
  },
  {
    path: '/groups',
    name: 'group.list',
    component: GroupModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['counteragent_groups_block'],
    },
  },
  {
    path: '/owner',
    name: 'owner.list',
    component: OwnerModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['owners_block'],
    },
  },
  {
    path: '/tranports',
    name: 'transport.list',
    component: TransportModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['transport_block'],
    },
  },
  {
    path: '/reports/product-collection',
    name: 'warehouse.report.product-collection',
    component: ProductCollectionReport,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_production_block'],
    },
  },
  {
    path: '/reports/intermediate-production',
    name: 'warehouse.report.intermediate-production',
    component: IntermediateProduction,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_production_block'],
    },
  },
  {
    path: '/reports/intermediate-consignment',
    name: 'warehouse.report.intermediate-consignment',
    component: IntermediateConsignment,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_production_block'],
    },
  },
  {
    path: '/reports/product',
    name: 'warehouse.report.product',
    component: ProductReport,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_product_remain_block'],
    },
  },
  {
    path: '/reports/producement',
    name: 'warehouse.report.producement',
    component: ProducementReport,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_product_remain_block'],
    },
  },
  {
    path: '/reports/material',
    name: 'warehouse.report.material',
    component: MaterialReport,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_rawmaterial_remain_block'],
    },
  },
  {
    path: '/reports/counteragents',
    name: 'warehouse.report.counteragent',
    component: CounteragentReport,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_loaners_block'],
    },
  },
  {
    path: '/reports/operations',
    name: 'warehouse.report.operations',
    component: OperationsReport,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_income_expense_block'],
    },
  },
  {
    path: '/reports/expenditures-by-source',
    name: 'warehouse.report.expenditures-by-source',
    component: ExpendituresBySource,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_income_expense_block'],
    },
  },
  {
    path: '/reports/products-by-months',
    name: 'warehouse.report.products-by-months',
    component: ProductsByMonthsReport,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_monthly_block'],
    },
  },
  {
    path: '/reports/consignments-by-months',
    name: 'warehouse.report.consignments-by-months',
    component: ConsignmentsByMonthsReport,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_monthly_block'],
    },
  },
  {
    path: '/reports/turnover-by-months',
    name: 'warehouse.report.turnover-by-months',
    component: TurnoverByMonthsReport,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['report_oborot_block'],
    },
  },
  {
    path: '/reports/dashboard',
    name: 'warehouse.report.dashboard',
    component: DashboardReport,
    props: true,
    meta: {
      roles: ['boss'],
    },
  },

  {
    path: '/admin/users',
    name: 'admin.users',
    component: UserModule,
    props: true,
    meta: {
      roles: ['admin', 'boss'],
      permissions: ['settings_block'],
    },
  },

  {
    path: '/about',
    name: 'page.about',
    component: () => import(/* webpackChunkName: 'pages' */ '@/pages/about.vue')
  },
  {
    path: '/help',
    name: 'page.help',
    component: () => import(/* webpackChunkName: 'pages' */ '@/pages/help.vue')
  },
  {
    path: '/welcome',
    name: 'page.welcome',
    component: () => import(/* webpackChunkName: 'pages' */ '@/pages/welcome.vue')
  },
  {
    path: '/403',
    name: 'page.unauthorized',
    component: Unauthorized,
  },
  {
    path: '*',
    name: 'page.not-found',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(RouteService.navigate)

export default router
