import Vue from 'vue'
import {Resource} from 'vue-resource'

import ut from '@/utils'
import moment from 'moment'

import {SERVER_DATE_FORMAT} from '@/filters'
import {Currencies} from './currency-rates'

export const PaymentTypes = [
  {id: 'credit', name: 'Приход', altName: 'Кредит', color: 'success', altName2: 'Расход', color2: 'error', staffListName: 'Отработано'},
  {id: 'debet', name: 'Расход', altName: 'Дебет', color: 'error', altName2: 'Приход', color2: 'success', staffListName: 'Оплачено'},
]

export const PaymentForms = [
  {id: 'transfer', name: 'перечисление'},
  {id: 'cash', name: 'наличные'},
]

export const SourceTypes = [
  {id: 'counteragent', hasData: true, payment_types: ['debet', 'credit'], name: 'Контрагент', object_label: 'Контрагент'},
  {id: 'staff_salary', hasData: true, payment_types: ['credit'], name: 'Зарплата', object_label: 'Сотрудник'},
  {id: 'capital', hasData: true, payment_types: ['debet', 'credit', 'expense'], name: 'Капитал', object_label: 'Учредитель'},

  {id: 'transport_expenses', hasData: true, payment_types: ['credit', 'expense'], name: 'Затраты на транспорт', object_label: 'Транспорт'},
  {id: 'plant_expenses', hasData: false, payment_types: ['credit', 'expense'], name: 'Затраты на завод', object_label: 'Затраты на завод'},
  {id: 'eat_expenses', hasData: false, payment_types: ['credit', 'expense'], name: 'Затраты на питание', object_label: 'Затраты на питание'},
  {id: 'nonrefundable_expenses', hasData: false, payment_types: ['credit', 'expense'], name: 'Затраты без возврата', object_label: 'Затраты без возврата'},
  {id: 'bank_credit_expenses', hasData: false, payment_types: ['credit'], name: 'Кредиты', object_label: 'Кредиты'},
  {id: 'bank_credit_percent_expenses', hasData: false, payment_types: ['credit', 'expense'], name: 'Проценты кредита', object_label: 'Проценты кредита'},
  {id: 'bank_commissions_expenses', hasData: false, payment_types: ['credit', 'expense'], name: 'Банковская комиссия', object_label: 'Банковская комиссия'},
  {id: 'tax_expenses', hasData: false, payment_types: ['credit', 'expense'], name: 'Налоги', object_label: 'Налоги'},
  {id: 'ipoteka_expenses', hasData: false, payment_types: ['credit', 'expense'], name: 'Ипотека', object_label: 'Ипотека'},
  {id: 'conversion', hasData: false, payment_types: ['debet', 'credit'], name: 'Конверсия', object_label: 'Конверсия'},
  {id: 'other_expenses', hasData: false, payment_types: ['debet', 'credit'], name: 'Другие', object_label: 'Другие'},
]

SourceTypes.getIds = function () {
  return this.map(s => s.id)
}
SourceTypes.getFor = function (payment_type) {
  return this.filter(s => !payment_type || s.payment_types.indexOf(payment_type) >= 0)
}

const CrossCurrencyData = {
  main_currency: Currencies[0].id,
  amount: 0,
  cross_currency: Currencies[1].id,
  cross_currency_amount: 0,
  rate: 0,
}

const Payment = {
  id: null,
  reg_number: '',
  reg_date: moment().format(SERVER_DATE_FORMAT),
  type: '',
  cash_type: PaymentForms[0].id,
  currency: Currencies[0].id,
  total: '',
  comments: '',

  source: '',
  counteragent: null,
  owner: null,
  staff: null,
  transport: null,

  cross_currency: false,
  cross_currency_data: ut.deepClone(CrossCurrencyData),
}

export const PaymentResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'payment/list/',
  },
  details: {
    method: 'GET',
    url: 'payment/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'payment/create/',
  },
  update: {
    method: 'PUT',
    url: 'payment/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'payment/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    PaymentTypes,
    PaymentForms,
    SourceTypes,

    data: null,

    fetching: false,
    fetchingDetails: false,
    model: ut.deepClone(Payment),
    saving: false,
    deleting: {},
  },

  getters: {
    getSourceType: (state) => (id) => SourceTypes.find(m => m.id === id) || {},
    getPaymentType: (state) => (id) => PaymentTypes.find(m => m.id === id) || {},
    getPaymentForm: (state) => (id) => PaymentForms.find(p => p.id === id) || {},
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(Payment), ...data}

      if (state.model.counteragent && ('object' === typeof state.model.counteragent)) {
        state.model.counteragent_full_name = state.model.counteragent.full_name
        state.model.counteragent = state.model.counteragent.id
      }
    },

    setCrossCurrencyData(state, data) {
      state.model.cross_currency_data = {...ut.deepClone(CrossCurrencyData), ...data}
    }
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = PaymentResource
                            .list({...ut.objFilter(filters), offset: filters.limit * (filters.page - 1)})
                            .then((response) => {
                              commit('setItems', response.body)
                            })
                            .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit}, id) {
      if (!state.fetchingDetails) {
        commit('setModel', {})
        state.fetchingDetails = PaymentResource.details({id})
          .then(({body}) => {
            commit('setModel', body)
          })
          .finally(() => state.fetchingDetails = false)
      }

      return state.fetchingDetails
    },

    save({state, commit}) {
      state.saving = true
      let promise
      if (state.model.id) {
        promise = PaymentResource.update({id: state.model.id}, ut.objFilter(state.model))
      } else {
        promise = PaymentResource.create(ut.objFilter(state.model))
      }

      return promise
        .then(({body}) => {
          commit('setModel', body)
        })
        .finally(() => state.saving = false)
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return PaymentResource.remove({id})
                            .then(() => dispatch('fetch'))
                            .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
