import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {double, min_value, integer, max, required, excluded, email} from 'vee-validate/dist/rules'

import {localize} from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'

localize({
  ru: {
    ...ru,
    names: {
      username: 'логин',
      password: 'пароль',
      name: 'название',
      recipe_doc: 'рецепты',
      sell_price: 'цена продажи',
      sell_price_usd: 'цена продажи в валюте',
      revinue_amount: 'маржа',
      warehouse_name: 'название склада',
      warehouse_type: 'тип',
      description: 'описание',
      total: 'сумма',
      region: 'область',
      district: 'район/город',
    }
  }
})
localize('ru')

extend('integer', integer)
extend('double', double)
extend('max', max)
extend('required', required)
extend('min_value', min_value)
extend('excluded', excluded)
extend('email', email)
extend('uzphone', val => {
  return val.toString().length === 9
})
extend('min', {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ['length']
})
extend('positive', {
  validate: value => value > 0,
  message: 'Введите правильное положительное число',
})
extend('maxOrZero', {
  validate(value, args) {
    return +value >= 0 && +value <= +args[0];
  },
  message: 'Введите правильное положительное число',
})

export default {
  install(VueInstance) {
    VueInstance.component('ValidationObserver', ValidationObserver)
    VueInstance.component('ValidationProvider', ValidationProvider)
    VueInstance.prototype.$setLocale = localize
  }
}
