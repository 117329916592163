import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'

const Recipe = {
  id: null,
  name: '',
  description: '',
  recipe_elements: [],
}

const RecipeItem = {
  id: null,
  raw_material: null,
  amount: '',
  recipe_doc: null,
}

const RecipeResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/recipedoc/list/',
  },
  details: {
    method: 'GET',
    url: 'warehouse/recipedoc/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'warehouse/recipedoc/create/',
  },
  update: {
    method: 'PUT',
    url: 'warehouse/recipedoc/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'warehouse/recipedoc/delete/{id}/',
  },
})

const RecipeItemResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/recipelement/list/',
  },
  details: {
    method: 'GET',
    url: 'warehouse/recipelement/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'warehouse/recipelement/create/',
  },
  update: {
    method: 'PUT',
    url: 'warehouse/recipelement/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'warehouse/recipelement/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    data: null,

    fetching: false,
    model: ut.deepClone(Recipe),
    recipeItems: [],

    saving: false,
    deleting: {}
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(Recipe), ...data}
    },

    setRecipeItems(state, items) {
      state.recipeItems = items

      state.recipeItems.map(el => {
        if (el.raw_material && ('object' === typeof el.raw_material)) {
          el.raw_material_name = el.raw_material.name

          if (el.raw_material.measurement && ('object' === typeof el.raw_material.measurement)) {
            el.raw_material_measurement = el.raw_material.measurement.id
          } else {
            el.raw_material_measurement = el.raw_material.measurement
          }

          el.raw_material = el.raw_material.id
        }

        if (el.recipe_doc && ('object' === typeof el.recipe_doc)) {
          el.recipe_doc = el.recipe_doc.id
        }
      })
    },

    createItem(state, data = {}) {
      state.recipeItems.push({...ut.deepClone(RecipeItem), ...data})
    },
    setItem(state, {index, item}) {
      Vue.set(state.recipeItems, index, item)
    },
    clearItem(state, index) {
      state.recipeItems.splice(index,1)
    }
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = RecipeResource.list(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit}, id) {
      if (!state.fetching) {
        commit('setModel', {})
        state.fetching = RecipeResource.details({id})
          .then(({body}) => {
            commit('setModel', body)
            if (body.recipeelements) {
              commit('setRecipeItems', body.recipeelements)
            }
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    save({state, commit}) {
      state.saving = true

      let promise
      if (state.model.id) {
        promise = RecipeResource.update({id: state.model.id}, state.model)
      } else {
        state.model.recipe_elements = state.recipeItems.map(ri => ut.objFilter(ri))
        promise = RecipeResource.create(state.model)
      }

      return new Promise((resolve, reject) => {
        promise.then(() => {
          if (state.model.id) {
            let promises = []
            state.recipeItems.forEach(ri => {
              if (ri.id) {
                promises.push(RecipeItemResource.update({id: ri.id}, ut.objFilter(ri)))
              } else {
                promises.push(RecipeItemResource.create({...ut.objFilter(ri), recipe_doc: state.model.id}))
              }
            })

            Promise.all(promises)
              .then(() => {
                commit('setModel', {})
                commit('setRecipeItems', [])
                resolve()
              })
              .catch(reject)
              .finally(() => state.saving = false)
          } else {
            commit('setModel', {})
            commit('setRecipeItems', [])
            state.saving = false
            resolve()
          }
        })
        .catch(() => {
          state.saving = false
          reject()
        })
      })
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return RecipeResource.remove({id})
        .then(() => dispatch('fetch'))
        .finally(() => Vue.set(state.deleting, id, false))
    },

    removeItem({state, dispatch}, index) {
      if (state.recipeItems[index].id) {
        Vue.set(state.deleting, index, true)
        return RecipeItemResource.remove({id: state.recipeItems[index].id})
          .then(() => {
            state.recipeItems.splice(index, 1)
          })
          .finally(() => Vue.set(state.deleting, index, false))
      } else {
        state.recipeItems.splice(index, 1)
      }
    },
  },
}
