import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'

const Roles = [
  {id: 'operator', title: ' Operator', home: 'page.welcome'},
  {id: 'agent', title: 'Agent', home: 'page.welcome'},
  {id: 'admin', title: 'Admin', home: 'page.welcome'},
  {id: 'boss', title: 'Boss', home: 'warehouse.report.dashboard'},
]

export const Permissions = [
  {key: 'contragents_module', title: 'Модуль контрагентов', hasDivider: true},
  {key: 'contragent_add_button', title: 'Разрешить создать контрагентов'},

  {key: 'purchase_block', title: 'Модуль закупок', hasDivider: true},
  {key: 'priyomka_add_button', title: 'Разрешить создать приёмку'},
  {key: 'can_priyomka_all_date', title: 'Разрешить установить старые даты приёмкам'},
  {key: 'transport_expenditure_block', title: 'Раздел транспортных расходов'},
  {key: 'transport_expenditure_add_button', title: 'Разрешить создать транспортные расходы'},
  {key: 'universal_expenditure_block', title: 'Раздел универсальных расходов'},
  {key: 'universal_expenditure_add_button', title: 'Разрешить создать универсальные расходы'},

  {key: 'production_block', title: 'Модуль производства', hasDivider: true},
  {key: 'product_document_block', title: 'Раздел документов продукта'},
  {key: 'can_product_all_date', title: 'Разрешить установить старые даты документам продуктов'},
  {key: 'products_block', title: 'Раздел продуктов'},
  {key: 'product_document_add_button', title: 'Разрешить добавить продукт'},
  {key: 'product_types_block', title: 'Раздел типов продукта'},
  {key: 'product_type_add_button', title: 'Разрешить добавить тип продукта'},
  {key: 'retsept_block', title: 'Раздел рецевтов'},
  {key: 'daily_volumes_block', title: 'Раздел дневных объем работ'},
  {key: 'retsept_add_button', title: 'Разрешить добавить рецепт'},
  {key: 'raw_material_block', title: 'Раздел материалов'},
  {key: 'raw_material_add_button', title: 'Разрешить добавить материал'},
  {key: 'measurements_block', title: 'Раздел ед. измерений'},

  {key: 'sale_block', title: 'Модуль продаж', hasDivider: true},
  {key: 'sale_add_button', title: 'Разрешить создать продажу'},
  {key: 'can_consignment_all_date', title: 'Разрешить установить старые даты продажам'},

  {key: 'return_block', title: 'Модуль возврата', hasDivider: true},
  {key: 'return_add_button', title: 'Разрешить создать возврат'},
  {key: 'can_return_all_date', title: 'Разрешить установить старые даты возвратам'},

  {key: 'finance_block', title: 'Модуль финансов', hasDivider: true},
  {key: 'kassa_block', title: 'Раздел кассы'},
  {key: 'payment_add_button', title: 'Разрешить добавить платеж'},
  {key: 'expenditures_block', title: 'Раздел затрат'},
  {key: 'currency_rate_block', title: 'Раздел курса валют'},
  {key: 'currency_rate_add_button', title: 'Разрешить добавить курс валют'},
  {key: 'salary_month_statement_block', title: 'Раздел зарплатных ведомостей'},
  {key: 'salary_month_statement_changes', title: 'Разрешить изменить заплатные ведомости'},

  {key: 'organization_block', title: 'Модуль организация', hasDivider: true},
  {key: 'owners_block', title: 'Раздел учредителей'},
  {key: 'staff_block', title: 'Раздел сотрудников'},
  {key: 'transport_block', title: 'Раздел транспорта'},
  {key: 'counteragent_groups_block', title: 'Раздел групп контрагентов'},

  {key: 'reports_block', title: 'Модуль отчетов', hasDivider: true},
  {key: 'report_production_block', title: 'Отчет по производству'},
  {key: 'report_product_remain_block', title: 'Отчет по остаткам'},
  {key: 'report_rawmaterial_remain_block', title: 'Отчет по остаткам материалов'},
  {key: 'report_loaners_block', title: 'Отчет по должникам'},
  {key: 'report_monthly_block', title: 'Отчет по месяцам'},
  {key: 'report_oborot_block', title: 'Отчет по обороту'},

  {key: 'settings_block', title: 'Модуль настроек', hasDivider: true},
]

const User = {
  id: null,
  first_name: '',
  last_name: '',
  username: '',
  password: null,
  user_type: 'operator',
  user_permissions: {},
}

const UserResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'user/list/',
  },
  details: {
    method: 'GET',
    url: 'user/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'user/create/',
  },
  update: {
    method: 'PATCH',
    url: 'user/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'user/delete/{id}/',
  },
})

const UserPermissionsResource = new Resource('/', {}, {
  update: {
    method: 'PUT',
    url: 'user/permissions/update/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    data: null,

    fetching: false,
    saving: false,
    model: ut.deepClone(User),
    deleting: {},

    Roles,
    Permissions,
  },

  getters: {
    getUser: (state) => (id) => state.data?.results?.find(m => m.id === id),
    getUsersByRole: (state) => (roleId) => state.data?.results?.filter(user => user.user_type === roleId) || [],
    getRole: (state) => (roleId) => Roles.find(r => r.id === roleId),
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(User), ...data}
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = UserResource
          .list(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit}, id) {
      if (!state.fetching) {
        commit('setModel', {})
        state.fetching = UserResource.details({id})
          .then(({body}) => {
            commit('setModel', body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    save({state, commit}) {
      state.saving = true

      return new Promise((resolve, reject) => {
        let promise

        if (state.model.id) {
          promise = UserResource.update({id: state.model.id}, ut.objFilter(state.model)).then(() => {
            UserPermissionsResource
              .update({id: state.model.user_permissions.id}, state.model.user_permissions)
              .then(resolve)
              .catch(reject)
          })
          .catch(reject)
        } else {
          promise = UserResource
                      .create(ut.objFilter(state.model))
                      .then(resolve)
                      .catch(reject)
        }

        return promise
                .then(() => {
                  setTimeout(() => commit('setModel', null), 1_000)
                })
                .finally(() => state.saving = false)
      })
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return UserResource.remove({id})
        .then(() => dispatch('fetch'))
        .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
