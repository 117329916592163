import store from '@/store'

export const UserService = {
  can(permissions) {
    return this.hasRole(['admin', 'boss']) || (Array.isArray(permissions) ? permissions : [permissions])
            .filter(perm => (store.state.Auth.user.permissions || {})[perm])
            .length > 0
  },

  hasRole(roles) {
    return (Array.isArray(roles) ? roles : [roles]).indexOf(store.state.Auth.user.role) > -1
  },

  getUserRole() {
    return store.state.Auth.user.role
  },

  isLogged() {
    return !!store.state.Auth.user?.id
  },

  isGuest() {
    return !this.isLogged()
  },

  canNavigate(route) {
    let permissions = route.meta?.permissions || []
    let roles = route.meta?.permissions || []

    if (permissions.length || roles.length) {
      return this.hasRole(roles) || this.can(permissions)
    }

    return true
  }
}
