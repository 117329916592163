var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{ref:"filtersContainer",staticClass:"mb-1"},[[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5",staticStyle:{"vertical-align":"middle"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-script-outline")]),_vm._v(" Список универсальных расходов ")],1),(_vm.userService.can('universal_expenditure_add_button'))?[_c('v-btn',{staticClass:"ml-3",staticStyle:{"vertical-align":"middle"},attrs:{"rounded":"","color":"primary","small":""},on:{"click":function($event){return _vm.showForm()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1),_c('v-dialog',{attrs:{"width":"70vw","persistent":""},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[(_vm.formDialog)?_c('universal-expense-form',{on:{"universal-expense:form:saved":_vm.closeForm,"universal-expense:form:cancel":_vm.closeForm}}):_vm._e()],1)]:_vm._e()],2),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.fromDateFormatted,"label":"с","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"disabled":!!_vm.fetching},model:{value:(_vm.filter.reg_date_after),callback:function ($$v) {_vm.$set(_vm.filter, "reg_date_after", $$v)},expression:"filter.reg_date_after"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.toDateFormatted,"label":"по","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{attrs:{"disabled":!!_vm.fetching},model:{value:(_vm.filter.reg_date_before),callback:function ($$v) {_vm.$set(_vm.filter, "reg_date_before", $$v)},expression:"filter.reg_date_before"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.counteragents,"item-value":"id","item-text":"full_name","prepend-inner-icon":"mdi-account-group","label":"Контрагент","loading":!!_vm.counteragentsLoading,"disabled":!!_vm.counteragentsLoading,"clearable":"","hide-details":""},model:{value:(_vm.filter.counteragent),callback:function ($$v) {_vm.$set(_vm.filter, "counteragent", $$v)},expression:"filter.counteragent"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.UniversalExpenseTypes,"item-value":"id","item-text":"label","prepend-inner-icon":"mdi-script-outline","label":"Тип расхода","clearable":"","hide-details":""},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}})],1)]],2),_c('v-data-table',{attrs:{"headers":_vm.nonSortableHeaders,"items":_vm.universalExpenses,"loading":!!_vm.fetching,"items-per-page":_vm.filter.limit,"height":_vm.dataTableHeight,"disable-items-per-page":"","hide-default-footer":"","fixed-header":"","no-data-text":"Нет данных","loading-text":"Данные загружаются"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.filter.limit * (_vm.filter.page - 1) + index + 1))]),_c('td',[_vm._v(_vm._s(_vm._f("date")(item.reg_date)))]),_c('td',[_vm._v(_vm._s((_vm.getCounteragent(item.counteragent) || {}).full_name))]),_c('td',[_vm._v(_vm._s(_vm.getType(item.type).label))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")(item.amount,null, true, false)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("shorten")(item.description,40)))]),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.showDetails(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":"","depressed":"","color":"primary","dark":""},on:{"click":function($event){return _vm.showForm(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":"","depressed":"","color":"error","loading":_vm.deleting[item.id]},on:{"click":function($event){return _vm.askRemove(item, _vm.removeEventName)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)])]}}])}),_c('pagination',{ref:"paginationContainer",attrs:{"limit":_vm.limit,"page":_vm.page,"total-pages":_vm.totalPages},on:{"update:limit":function($event){_vm.limit=$event},"update:page":function($event){_vm.page=$event}}}),(_vm.detailDialog)?_c('v-dialog',{attrs:{"width":"70vw"},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[_c('universal-expense-details',{on:{"dialog:close":_vm.closeDetails}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }