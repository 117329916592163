<template>
  <v-form>
    <div v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>
    <validation-observer v-else ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{model.id ? 'Изменить рецепт' : 'Создать рецепт'}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="name" rules="required|max:256">
                <v-text-field v-model="model.name"
                              required :error-messages="errors"
                              label="Название"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="description" rules="required|max:256">
                <v-text-field v-model="model.description"
                              required :error-messages="errors"
                              label="Описание"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <p class="my-0">Элементы</p>
            </v-col>
            <template v-for="(item, index) in recipeItems">
              <v-col cols="8">
                <validation-provider v-slot="{errors}" :name="`raw_material.${index}`" rules="required|double">
                  <v-autocomplete v-model="item.raw_material"
                            :items="materials" item-text="name" item-value="id"
                            required :error-messages="errors"
                            label="Сырье" dense
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider v-slot="{errors}" :name="`amount.${index}`" rules="required|double|positive">
                  <v-text-field v-model="item.amount"
                                required :error-messages="errors"
                                label="Кол-во" dense aria-valuemin="0"
                                append-outer-icon="mdi-close"
                                @click:append-outer="removeItem(index)"
                                :suffix="getSuffix(item)"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </template>
            <v-col cols="12">
              <v-btn @click="createItem" color="primary" small><v-icon small left>mdi-plus</v-icon> Добавить сырье</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 mt-2 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          >
            <v-icon left>mdi-content-save</v-icon>
            {{model.id ? 'Сохранить' : 'Создать'}}
          </v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          >
            <v-icon left>mdi-cancel</v-icon>
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'

  export default {
    name: 'recipe-form',

    data() {
      return {
        loading: true,
      }
    },

    computed: {
      ...mapState('Recipe', ['saving', 'model', 'recipeItems']),

      ...mapState('Material', {
        materials: state => state.data?.results || []
      }),

      ...mapGetters('Measurement', [
        'getMeasurement'
      ]),
    },

    methods: {
      ...mapMutations('Recipe', [
        'createItem',
      ]),
      ...mapActions('Recipe', [
        'removeItem',
      ]),

      getSuffix(item) {
        if (!item.raw_material) {
          return ''
        }

        let measurementId = (this.materials.find(m => m.id === item.raw_material) || {}).measurement

        return (this.getMeasurement(measurementId) || {}).name
      },

      cancel(reset) {
        reset()
        this.$emit('recipe:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Recipe/save')
          .then(() => {
            this.$emit('recipe:form:saved')
          })
      },
    },

    mounted() {
      Promise.all([
      ]).then(() => this.loading = false)
    }
  }
</script>
