import Vue from 'vue'
import ut from '@/utils'
import {Resource} from 'vue-resource';
import jwt_decode from 'jwt-decode'

import router from '@/router/index'

const AuthResource = Resource('users{/id}', {}, {
  all: {
    method: 'GET',
    url: 'user/list/',
  },
  login: {
    method: 'POST',
    url: 'auth/token/'
  },
  refreshToken: {
    method: 'POST',
    url: 'auth/token/refresh/'
  },
  logout: {
    method: 'POST',
    url: 'auth/logout'
  },
})

export const User = {
  username: '',
  password: '',
}

export const AuthForm = {
  username: '',
  password: '',
}

export default {
  namespaced: true,

  state: {
    user: {
      username: '',
      role: null,
      permissions: null,
    },
    authing: false,
  },

  mutations: {
    saveTokens(state, {access, refresh}) {
      localStorage.setItem('access_token', access)
      localStorage.setItem('refresh_token', refresh)
    },

    setUser(state, token) {
      if (token) {
        let {user_id, first_name, last_name, username, user_type, permissions} = jwt_decode(token)

        state.user.id = user_id
        state.user.role = user_type
        state.user.username = (first_name && last_name ? first_name + " " + last_name : '') || username || user_type
        state.user.permissions = permissions
      } else {
        state.user = {id: null}
      }

      Vue.$eventBus.$emit('auth:updated')
    },

    removeTokens() {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      this.commit('Auth/setUser', null)
    }
  },

  actions: {
    login({state, commit}, data) {
      if (!state.authing) {
        state.authing = AuthResource.login(ut.objFilter(data))
                          .then(({data}) => {
                            state.user.username = data.username
                            commit('saveTokens', data)
                            commit('setUser', data.access)
                            Vue.$eventBus.$emit('auth:success')
                          })
                          .catch(() => {
                            Vue.$eventBus.$emit('notification:error', 'Неправильная пара логин-пароль')
                          })
                          .finally(() => state.authing = false)
      }

      return state.authing
    },

    refreshToken({state, commit}) {
      if (!state.authing) {
        state.authing = AuthResource
                          .refreshToken(ut.objFilter({
                            token: localStorage.getItem('refresh_token')
                          }))
                          .then(({data}) => {
                            commit('saveTokens', data)
                            Vue.$eventBus.$emit('auth:success')
                          })
                          .catch((response) => {
                            Vue.$eventBus.$emit('notification:error', response.body.message)
                            if (router.currentRoute.name !== 'login') {
                              commit('removeTokens')
                              router.push({name: 'login'})
                            }
                          })
                          .finally(() => state.authing = false)
      }

      return state.authing
    },

    load({state, commit, dispatch}) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem('access_token')
        if (token) {
          commit('setUser', token)
          resolve()
        } else {
          if (localStorage.getItem('refresh_token')) {
            dispatch('refreshToken').then(resolve)
          } else {
            Vue.$eventBus.$emit('notification:error', 'Аутенифицируйтесь!')
            if (router.currentRoute.name !== 'login') {
              commit('removeTokens')
              router.push({name: 'login'})
            }
            resolve()
          }
        }
      })
    },
  }
}
