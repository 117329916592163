import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'

const Group = {
  id: null,
  name: '',
}

const LocationResource = new Resource('/', {}, {
  districts: {
    method: 'GET',
    url: 'common/district/list/',
  },
  regions: {
    method: 'GET',
    url: 'common/region/list/',
  },
})

export default {
  namespaced: true,

  state: {
    regions: null,
    districts: null,

    fetchingRegions: false,
    fetchingDistricts: false,
  },

  getters: {
    getRegion: (state) => (id) => state.regions?.results?.find(m => m.id === id),
    getDistrict: (state) => (id) => state.districts?.results?.find(m => m.id === id),
    getDistricts: (state) => (regionId) => state.districts?.results?.filter(m => m.region === regionId),
  },

  mutations: {
    setRegions(state, data) {
      state.regions = data
    },
    setDistricts(state, data) {
      state.districts = data
    },
  },

  actions: {
    fetch({state, commit}) {
      if (!state.fetchingRegions) {
        commit('setRegions', null)
        state.fetchingRegions = LocationResource.regions()
          .then((response) => {
            commit('setRegions', response.body)
          })
          .finally(() => state.fetchingRegions = false)
      }

      if (!state.fetchingDistricts) {
        commit('setDistricts', null)
        state.fetchingDistricts = LocationResource.districts({limit: 1000})
          .then((response) => {
            commit('setDistricts', response.body)
          })
          .finally(() => state.fetchingDistricts = false)
      }

      return Promise.all([state.fetchingRegions, state.fetchingDistricts])
    }
  }
}
