<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{model.id ? 'Изменить контракт' : 'Создать контракт'}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="code" rules="required|integer">
                <v-text-field v-model="model.code"
                              required :error-messages="errors"
                              label="Код"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="reg_number" rules="required|max:256">
                <v-text-field v-model="model.reg_number"
                              required :error-messages="errors"
                              label="Регистрационный номер"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="price" rules="required|integer">
                <v-text-field v-model="model.price"
                              required :error-messages="errors"
                              label="Сумма договора" suffix="сум"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="counteragent" rules="required|integer">
                <v-select v-model="model.counteragent"
                          :items="counteragents"
                          item-value="id" item-text="full_name"
                          required :error-messages="errors"
                          label="Контрагент"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="comment" rules="max:512">
                <v-textarea v-model="model.comment" label="Комментарий"></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          ><v-icon left>mdi-content-save</v-icon> {{model.id ? 'Сохранить' : 'Создать'}}</v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          ><v-icon left>mdi-cancel</v-icon> Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'contract-form',

    computed: {
      ...mapState('Contract', ['saving', 'model']),

      ...mapState('Counteragent', {
        counteragents: state => state.data?.results || []
      }),
    },

    methods: {
      cancel(reset) {
        reset()
        this.$emit('contract:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Contract/save')
          .then(() => {
            this.$emit('contract:form:saved')
          })
      },
    },
  }
</script>
