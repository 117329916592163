import Vue from 'vue'
import ut from '@/utils'
import {SERVER_DATE_FORMAT} from '@/filters'
import moment from 'moment'

import {Resource} from 'vue-resource'

const TransportExpenseTypes = [
  {id: 'metan', label: 'Метан'},
  {id: 'dizel', label: 'Дизел'},
  {id: 'petrol', label: 'Бензин'},
  {id: 'other', label: 'Другое'},
]

const TransportExpense = {
  id: null,
  reg_date: moment().format(SERVER_DATE_FORMAT),
  type: TransportExpenseTypes[0].id,
  counteragent: null,
  description: '',
  transport: null,
  amount: null,
}

const TransportExpenseResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'transport/expenditures/list/',
  },
  details: {
    method: 'GET',
    url: 'transport/expenditures/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'transport/expenditures/create/',
  },
  update: {
    method: 'PUT',
    url: 'transport/expenditures/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'transport/expenditures/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    TransportExpenseTypes,

    data: null,

    fetching: false,
    fetchingDetails: false,
    model: ut.deepClone(TransportExpense),

    saving: false,
    deleting: {}
  },

  getters: {
    getType: (state) => (id) => TransportExpenseTypes.find(e => e.id === id),
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    updateModel(state, data) {
      state.model = {...state.model, ...data}
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(TransportExpense), ...data}
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = TransportExpenseResource.list(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    save({state, commit}) {
      state.saving = true

      let promise
      if (state.model.id) {
        promise = TransportExpenseResource.update({id: state.model.id}, state.model)
      } else {
        promise = TransportExpenseResource.create(state.model)
      }

      return promise.then(() => {
                commit('setModel', {})
                state.saving = false
             })
             .catch(({body}) => Vue.$eventBus.$emit('notification:error', body.detail))
            .finally(() => state.saving = false)
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return TransportExpenseResource.remove({id})
              .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
