<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="6" class="d-flex align-center pb-0">
        <span class="text-h5">Список рецептов</span>
        <v-dialog v-if="userService.can('retsept_add_button')" v-model="formDialog" width="500" persistent>
          <template #activator="{on, attrs}">
            <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary"
                   v-bind="attrs" v-on="on" small
            >
              <v-icon left>mdi-plus</v-icon> Добавить
            </v-btn>
          </template>
          <recipe-form v-if="formDialog"
                          @recipe:form:saved="closeForm"
                          @recipe:form:cancel="closeForm"
          ></recipe-form>
        </v-dialog>
      </v-col>
      <v-col offset="3" cols="3" class="pb-0">
        <v-text-field v-model="filter.name" :disabled="!!fetching"
                      label="Поиск" prepend-inner-icon="mdi-magnify"
                      ref="name_filter"
                      clearable hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="nonSortableHeaders"
      :items="recipes"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.name}}</td>
          <td>{{item.description}}</td>
          <td class="text-right">
            <v-btn class="ml-2" text small depressed color="success" @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon>
              Показать
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            >
              <v-icon left small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-model="detailDialog" width="60vw">
      <recipe-details @dialog:close="closeDetails"
                      @dialog:edit="editForm"
      ></recipe-details>
    </v-dialog>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'
  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('Recipe')

  import RecipeDetails from '@/modules/recipe/details'
  import RecipeForm from '@/modules/recipe/form'

  import {hasFilter, hasDialog} from '@/mixins'

  export default {
    name: 'recipe-list',

    mixins: [hasFilter, hasDialog],

    refocusable: {
      name: 'name_filter',
    },

    customFilters: {
      name: '',
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState(['fetching', 'deleting', 'data']),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Название'},
          {text: 'Описание'},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      recipes() {
        return this.data?.results || []
      },
    },

    methods: {
      ...mapActions(['fetch', 'remove', 'show']),
      ...mapMutations(['setModel', 'setRecipeItems']),

      closeDetails() {
        this.setModel({})
        this.setRecipeItems([])
        this.detailDialog = false
      },

      editForm(item) {
        this.showForm(item)
        this.detailDialog = false
      },

      showForm(item) {
        this.show(item.id)
        this.formDialog = true
      },

      closeForm() {
        this.formDialog = false
        this.setModel({})
        this.setRecipeItems([])
        this.fetch(this.filter)
      },
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Список рецетов')
      this.$store.dispatch('Material/fetch')
      this.$store.dispatch('Measurement/fetch')
    },

    components: {
      RecipeForm,
      RecipeDetails,
    }
  }
</script>
