<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <template v-if="!embedded">
        <v-col cols="12" class="pb-0">
          <span style="vertical-align:middle;" class="text-h5">
            <v-icon color="black">mdi-gas-station</v-icon> Список траспортных расходов
          </span>
          <template v-if="userService.can('transport_expenditure_add_button')">
            <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
                   @click="showForm()"
            >
              <v-icon left>mdi-plus</v-icon> Добавить
            </v-btn>
            <v-dialog v-model="formDialog" width="70vw" persistent>
              <transport-expense-form v-if="formDialog"
                                      @transport-expense:form:saved="closeForm"
                                      @transport-expense:form:cancel="closeForm"
              ></transport-expense-form>
            </v-dialog>
          </template>
        </v-col>

        <v-col cols="2">
          <v-menu v-model="fromDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="fromDateFormatted"
                            label="с" prepend-inner-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on" hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.reg_date_after" :disabled="!!fetching"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-menu v-model="toDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="toDateFormatted"
                            label="по" prepend-inner-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on" hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.reg_date_before" :disabled="!!fetching"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-autocomplete v-model="filter.counteragent" :items="counteragents"
                          item-value="id" item-text="full_name"
                          prepend-inner-icon="mdi-account-group" label="Контрагент"
                          :loading="!!counteragentsLoading" :disabled="!!counteragentsLoading"
                          clearable hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete v-model="filter.transport" :items="transports"
                          item-value="id" item-text="label"
                          prepend-inner-icon="mdi-car" label="Транспортное средство"
                          :loading="!!counteragentsLoading" :disabled="!!counteragentsLoading"
                          clearable hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete v-model="filter.type" :items="TransportExpenseTypes"
                          item-value="id" item-text="label"
                          prepend-inner-icon="mdi-fuel" label="Тип расхода"
                          clearable hide-details
          ></v-autocomplete>
        </v-col>
      </template>
    </v-row>

    <v-data-table
      :headers="nonSortableHeaders"
      :items="transportExpenses"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.reg_date|date}}</td>
          <td>{{(getCounteragent(item.counteragent) || {}).full_name}}</td>
          <td>{{getType(item.type).label}}</td>
          <td class="text-right">{{item.amount|money()}}</td>
          <td>{{(getTransport(item.transport) || {}).number}} - {{(getTransport(item.transport) || {}).model}}</td>
          <td class="text-right">{{item.description}}</td>
          <td v-if="!embedded" class="text-right">
              <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
                <v-icon small>mdi-pen</v-icon>
              </v-btn>
              <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item, removeEventName)"
                     :loading="deleting[item.id]"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import {mapState as defaultMapState, mapGetters as defaultMapGetters, createNamespacedHelpers} from 'vuex'
  const {mapState, mapGetters, mapActions, mapMutations} = createNamespacedHelpers('TransportExpense')

  import TransportExpenseForm from '@/modules/transport-expense/form'
  import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'

  import {hasFilter, hasDialog} from '@/mixins'

  export default {
    name: 'transport-expense-list',

    mixins: [hasFilter, hasDialog],

    props: {
      transportId: {
        type: Number,
      },
      embedded: Boolean,
    },

    customFilters: {
      reg_date_after: moment().subtract(1, 'month').format(SERVER_DATE_FORMAT),
      reg_date_before: moment().format(SERVER_DATE_FORMAT),
      counteragent: null,
      transport: null,
      type: null,
    },

    data() {
      return {
        removeEventName: 'transport-expense:removed',
      }
    },

    created() {
      if (this.transportId) {
        this.filter.transport = this.transportId
      } else {
        this.fetch(this.filter)
      }

    },

    computed: {
      ...mapState([
        'TransportExpenseTypes',
        'fetching',
        'data',
        'deleting',
      ]),
      ...mapGetters(['getType']),

      headers() {
        let headers = [
          {text: '#', align: 'center', width: '75'},
          {text: 'Дата'},
          {text: 'Контрагент'},
          {text: 'Тип'},
          {text: 'Сумма', align: 'right'},
          {text: 'ТС'},
          {text: 'Комментарий', align: 'right'},
        ]

        if (!this.embedded) {
          headers.push({text: 'Действия', align: 'right', sortable: false})
        }

        return headers
      },

      transportExpenses() {
        return this.data?.results || []
      },

      ...defaultMapState('Counteragent', {
        counteragents: state => (state.data?.results || []).filter(c => c.client_type === 'gasoil'),
        counteragentsLoading: state => !!state.fetching,
      }),
      ...defaultMapGetters('Counteragent', ['getCounteragent']),

      ...defaultMapState('Transport', {
        transports: state => (state.data?.results || []).map(t => {
          return {
            id: t.id,
            label: t.number + ' - ' + t.model,
          }
        }),
        transportsLoading: state => !!state.fetching,
      }),
      ...defaultMapGetters('Transport', ['getTransport']),

      toDateFormatted() {
        return moment(this.filter.reg_date_before).format(USER_DATE_FORMAT)
      },

      fromDateFormatted() {
        return moment(this.filter.reg_date_after).format(USER_DATE_FORMAT)
      },
    },

    methods: {
      ...mapActions(['fetch', 'remove']),
      ...mapMutations(['setModel']),

      showForm(item = {}) {
        this.setModel(item)
        this.formDialog = true
      },
    },

    mounted() {
      if (!this.embedded) {
        this.$eventBus.$emit('setTitle', 'Список траспортных расходов')
      }

      this.$eventBus.$on(this.removeEventName, () => this.fetch(this.filter))
      this.$store.dispatch('Counteragent/fetch')

      if (!this.transportId) {
        this.$store.dispatch('Transport/fetch')
      }
    },

    beforeDestroy() {
      this.$eventBus.$off(this.removeEventName)
    },

    components: {
      TransportExpenseForm,
    }
  }
</script>
