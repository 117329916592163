<template>
  <v-container>
    <v-row align="center" justify="center">
        <v-col sm="12" md="4" lg="3">
          <img width="100%" src="@/assets/storehouse.webp">
        </v-col>
    </v-row>
    <v-row v-if="inited" align="center" justify="center" class="flex-column">
        <v-col sm="12" md="4" lg="3" class="text-center">
            <p class="display-1">Авторизация</p>
            <validation-observer ref="observer" v-slot="{handleSubmit}">
                <form ref="loginForm">
                    <validation-provider v-slot="{errors}" name="username" rules="required">
                        <v-text-field v-model="form.username"
                                      :error-messages="errors" outlined
                                      label="Логин" required :disabled="!!authing"
                        ></v-text-field>
                    </validation-provider>
                    <validation-provider v-slot="{errors}" name="password" rules="required">
                        <v-text-field v-model="form.password"
                                      current-password outlined
                                      :error-messages="errors"
                                      label="Пароль" required :disabled="!!authing"
                                      :type="showPassword ? 'text' : 'password'"
                                      @click:append="showPassword = !showPassword"
                                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                      @keydown.enter="handleSubmit(login)"
                        ></v-text-field>
                    </validation-provider>
                    <v-btn :disabled="!!authing" :loading="!!authing"
                           @click="handleSubmit(login)"
                           color="primary" class="mt-2"
                    >Войти</v-btn>
                </form>
            </validation-observer>
        </v-col>
        <v-col sm="12" md="4" lg="3" class="mt-5 text-center d-flex flex-column">
          <v-btn link text depressed color="primary" :to="{name: 'page.help'}">
            <v-icon small left>mdi-chat-question</v-icon> Есть вопросы?
          </v-btn>
          <v-btn link text depressed color="primary" :to="{name: 'page.about'}">
            <v-icon small left>mdi-information</v-icon> О нас
          </v-btn>
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="center">
        <v-col cols="12">
            <v-row align="center" justify="center" class="mt-4">
                <v-progress-circular size="30" width="2" color="primary" indeterminate></v-progress-circular>
            </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
    import {AuthForm}   from '@/store/auth'
    import ut           from '@/utils'
    import {RouteService} from "@/services";

    export default {
        name: 'login',

        data() {
            return {
                showPassword: false,
                form: {},
                inited: false,
                pl: 0,
            }
        },

        computed: {
            ...mapState(['drawer']),
            ...mapState('Auth', [
                'authing',
                'user',
            ]),
            ...mapGetters('User', ['getRole'])
        },

        methods: {
            login() {
                this.$store.dispatch('Auth/login', this.form)
            },
        },

        created() {
            if (this.user.id) {
                this.$router.push(RouteService.redirectLogged())
            }

            this.inited = true
            this.form = ut.deepClone(AuthForm)

            this.$eventBus.$on('auth:success', () => {
                this.$store.dispatch('load')
                  this.$router.push(RouteService.redirectLogged())
            })
        },

        beforeDestroy() {
            this.$eventBus.$off('auth:success')
        }
    }
</script>
