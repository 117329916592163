import moment from 'moment'
import {Currencies} from '@/store/currency-rates'

export const USER_DATE_FORMAT = 'DD-MM-YYYY'
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:00'
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD'

export const filters = {
  serverDate(date) {
    return date ? moment(date).locale('ru').format(SERVER_DATE_FORMAT) : ''
  },

  dateTime(date) {
    return date ? moment(date).locale('ru').format('DD.MM.YYYY, HH:mm') : ''
  },

  stringDateTime(date) {
    return date ? moment(date).locale('ru').format('LL') : ''
  },

  yearMonth(date) {
    return date ? moment(date).locale('ru').format('MMMM YYYY') : ''
  },
  monthDay(date) {
    return date ? moment(date).locale('ru').format('DD.MM') : ''
  },

  date(date) {
    return date ? moment(date).locale('ru').format('DD.MM.YYYY') : ''
  },

  money(x = 0, currency = null, round = false, showCurrency = true) {
    let curr = Currencies.find(c => c.id === currency) || Currencies[0]
    let price = round ? parseFloat(x.toFixed(curr.precision)) : x

    return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${showCurrency ? curr.format : ''}`
  },

  prettyInt(x = 0) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  },

  phone(rawPhone) {
    return (rawPhone || '').toString()
            .replace(/[^0-9]/g, '')
            .padStart(12, '998')
            .replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/g, "+$1 ($2) $3-$4-$5")
          || 'Not set'
  },

  userName(user = {}) {
    return user.full_name || user.login
  },

  full_name(staff = {}) {
    return `${staff.last_name || ''} ${staff.first_name || ''} ${staff.middle_name || ''}`
  },

  shorten(str, maxLength = 50) {
    return str.length > maxLength ? str.toString().substring(0, maxLength) + '...' : str
  },
}

const filterInstaller = {
  install(Vue) {
    for (let key in filters) {
      Vue.filter(key, filters[key])
    }
  }
}

export {filterInstaller}
