import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './auth'
import Contract from './contract'
import CurrencyRate from './currency-rates'
import Consignment from './consignment'
import Counteragent from './counteragent'
import Expense from './expense'
import Group from './group'
import Location from './location'
import Material from './material'
import Measurement from './measurement'
import Owner from './owner'
import Payment from './payment'
import StartingPoint from './starting-point'
import Product from './product'
import ProductItem from './product-item'
import ProductCollection from './product-collection'
import Recipe from './recipe'
import Report from './report'
import Staff from './staff'
import SalaryStatement from './salary-statement'
import Transfer from './transfer'
import Transport from './transport'
import TransportExpense from './transport-expense'
import UniversalExpense from './universal-expense'
import User from './user'
import Warehouse from './warehouse'

Vue.use(Vuex)
Vue.prototype.$eventBus = Vue.$eventBus = new Vue

let _resolver

const store = new Vuex.Store({
  modules: {
    Auth,
    Consignment,
    Contract,
    CurrencyRate,
    Counteragent,
    Expense,
    Group,
    Location,
    Material,
    Measurement,
    Owner,
    Payment,
    StartingPoint,
    Product,
    ProductItem,
    ProductCollection,
    Recipe,
    Report,
    Staff,
    SalaryStatement,
    Transfer,
    Transport,
    TransportExpense,
    UniversalExpense,
    User,
    Warehouse,
  },

  state: {
    initing: new Promise((resolve) => {
      _resolver = resolve
    }),
    inited: false,
    miniDrawer: false,
    appSettings: {
      enableStartRemainBalanceForm: true,
    }
  },

  getters: {
  },

  mutations: {
    inited(state) {
      state.inited = true
      _resolver()
    },

    resizeDrawer(state) {
      state.miniDrawer = !state.miniDrawer
    },
  },

  actions: {
    load({commit, dispatch}) {
      Promise.all([
        dispatch('Auth/load'),
        dispatch('Location/fetch'),
        dispatch('Group/fetch'),
        dispatch('CurrencyRate/current'),
      ])
      .finally(() => {
        commit('inited')
      })
    },
  }
})

export default store
