import ut from '@/utils'
import {Resource} from 'vue-resource'

const ProductResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/product/list/',
  },
})

export default {
  namespaced: true,

  state: {
    data: null,
    fetching: false,
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = ProductResource.list(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },
  },
}
