<template>
  <v-card :loading="!!fetching">
    <v-card-title>
      Документы продукта #{{model.id}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6">
          <div>Дата регистрации: {{model.reg_date|date}}</div>
          <div>Описание: {{model.description}}</div>
        </v-col>
        <v-col cols="6">
          <div>Со склада: {{getWarehouseName(model.from_warehouse)}}</div>
          <div>На склад: {{getWarehouseName(model.warehouse)}}</div>
        </v-col>
      </v-row>

    <h2 class="mt-4 mb-3 font-weight-regular text--primary">Продукты</h2>
    <v-row><v-col cols="12">
      <v-data-table hide-default-footer
                    :headers="productItemHeaders" :items="model.products"
      >
        <template #item="{item, index}">
          <tr>
            <td class="text-center">{{index + 1}}</td>
            <td>{{item.product_type_name}}</td>
            <td class="text-right">{{item.amount|prettyInt}}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col></v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers, mapGetters} from 'vuex'
  const {mapState} = createNamespacedHelpers('ProductCollection')

  export default {
    name: 'product-details',

    computed: {
      ...mapState(['fetching', 'model']),
      ...mapGetters('Warehouse', ['getWarehouseName']),
      productItemHeaders() {
        return [
          {text: '#', width: '70px'},
          {text: 'Название продукта', width: '300px'},
          {text: 'Кол-во', width: '120px', align: 'center'},
        ]
      }
    },

    mounted() {
      this.$store.dispatch('Warehouse/fetch')
    }
  }
</script>
