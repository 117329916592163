<template>
  <v-btn v-if="user && user.id" :loading="!!fetchingCurrent"
         :to="clickable ? {name: 'currency-rates.list'} : null"
         text :color="dark ? 'white' : 'primary'"
  >
    1$ = {{(current || {}).rate | money}} - {{(current || {}).updated_at | date}}
  </v-btn>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'currency-rates-list',

    props: {
      dark: Boolean,
      clickable: Boolean,
    },

    computed: {
      ...mapState('Auth', ['user']),
      ...mapState('CurrencyRate', ['fetchingCurrent', 'current'])
    },
  }
</script>
