import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'
import moment from 'moment'
import {SERVER_DATE_FORMAT} from '@/filters'

const ProductCollection = {
  id: null,
  reg_date: moment().format(SERVER_DATE_FORMAT),
  from_warehouse: null,
  warehouse: null,
  description: '',
  products: [],
}

export const ProductCollectionItem = {
  id: null,
  product_type: null,
  amount: '',
}

const ProductCollectionResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/productdoc/list/',
  },
  details: {
    method: 'GET',
    url: 'warehouse/productdoc/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'warehouse/productdoc/create/',
  },
  createStartingPoint: {
    method: 'POST',
    url: 'warehouse/productdoc/start-create/',
  },
  update: {
    method: 'PUT',
    url: 'warehouse/productdoc/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'warehouse/productdoc/delete/{id}/',
  },
})

const ProductCollectionItemResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/product/list/',
  },
  create: {
    method: 'POST',
    url: 'warehouse/product/create/',
  },
  update: {
    method: 'PATCH',
    url: 'warehouse/product/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'warehouse/product/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    data: null,

    fetching: false,
    model: ut.deepClone(ProductCollection),
    collectionItems: [],
    deletingItems: [],

    saving: false,
    deleting: {}
  },

  getters: {
    getProduct: (state) => (id) => state.data?.results?.find(m => m.id === id),
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(ProductCollection), ...data}
    },

    updateModel(state, data) {
      state.model = {...state.model, ...data}
    },

    setCollectionItems(state, items) {
      state.collectionItems = items

      state.collectionItems.map(el => {
        if (el.product_type && ('object' === typeof el.product_type)) {
          el.product_type_name = el.product_type.name
          el.product_type = el.product_type.id
        }

        if (el.product_doc && ('object' === typeof el.product_doc)) {
          el.product_doc = el.product_doc.id
        }
      })

      if (!items.length) {
        state.deletingItems = []
      }
    },

    createCollectionItem(state, data = {}) {
      state.collectionItems.push({...ut.deepClone(ProductCollectionItem), ...{amount: 1}, ...data})
    },
    setCollectionItem(state, {index, item}) {
      Vue.set(state.collectionItems, index, item)
    },
    removeCollectionItem(state, index) {
      if (state.collectionItems[index].id) {
        state.deletingItems.push(state.collectionItems[index])
      }
      state.collectionItems.splice(index,1)
    }
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = ProductCollectionResource.list(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit}, id) {
      if (!state.fetching) {
        commit('setModel', {})
        state.fetching = ProductCollectionResource.details({id})
          .then(({body}) => {
            commit('setModel', body)
            if (body.products) {
              commit('setCollectionItems', body.products)
            }
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    save({state, commit}, startingPoint = false) {
      state.saving = true
      let promise
      if (state.model.id) {
          promise = ProductCollectionResource.update({id: state.model.id}, state.model)
      } else {
        state.model.products = state.collectionItems.map(ci => ut.objFilter(ci))
        if (startingPoint) {
          promise = ProductCollectionResource.createStartingPoint(state.model)
        } else {
          promise = ProductCollectionResource.create(state.model)
        }
      }

      return new Promise((resolve, reject) => {
        promise.then(() => {
          if (state.model.id) {
            let promises = []
            state.collectionItems.forEach(pi => {
              if (pi.id) {
                promises.push(ProductCollectionItemResource.update({id: pi.id}, ut.objFilter(pi)))
              } else {
                promises.push(ProductCollectionItemResource.create({...ut.objFilter(pi), product_doc: state.model.id}))
              }
            })

            state.deletingItems.forEach(di => {
              promises.push(ProductCollectionItemResource.remove({id: di.id}))
            })

            Promise.all(promises)
              .then(() => {
                resolve()
                commit('setModel', {})
                commit('setCollectionItems', [])
              })
              .catch(({body}) => {
                Vue.$eventBus.$emit('notification:error', body.detail)
                reject()
              })
              .finally(() => state.saving = false)
          } else {
            commit('setModel', {})
            commit('setCollectionItems', [])
            state.saving = false
            resolve()
          }
        })
        .catch(({body}) => {
          Vue.$eventBus.$emit('notification:error', body.detail)
          reject()
        })
        .finally(() => state.saving = false)
      })
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return ProductCollectionResource.remove({id})
        .then(() => dispatch('fetch'))
        .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
