import {RouteService, UserService} from '@/services'

const extensions = [
  {
    data() {
      return {
        userService: UserService,
        routeService: RouteService,
        dataTableHeight: '160px',
      }
    },

    methods: {
      calcDataTableHeight() {
        let total = 0

        total += this.$refs.filtersContainer?.clientHeight || 0
        total += parseInt((this.$root.$children || [{$refs: {}}])[0]?.$refs.globalHeaderContainer?.styles?.height || 0)
        total += this.$refs.paginationContainer?.$el.clientHeight || 0
        total += this.dataTableExtraOffset || 0

        return `calc(100vh - ${total + 10}px)`
      }
    },

    updated() {
      this.dataTableHeight = this.calcDataTableHeight()
    }
  }
]

import pagination from '@/components/pagination'
import staffWorkType from '@/components/staff/workType'

export default {
  install(Vue) {
    extensions.forEach(e => Vue.mixin(e))

    Vue.component('pagination', pagination)
    Vue.component('staff-work-type', staffWorkType)
  }
}
