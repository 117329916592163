<template>
  <v-container fluid>
    <v-row ref="filtersContainer">
      <v-col cols="8" class="d-flex align-center">
        <span class="text-h5">Список документов продукта</span>
        <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
               @click="showForm()"
        >
          <v-icon left>mdi-plus</v-icon> Добавить
        </v-btn>
        <v-dialog v-model="formDialog" width="60%" persistent>
          <product-form v-if="formDialog"
                        :starting-point="startingPoint"
                        @product-collection:form:saved="closeFormDialog"
                        @product-collection:form:cancel="closeFormDialog"
          ></product-form>
        </v-dialog>
      </v-col>
      <v-col cols="4" class="d-flex align-center justify-end">
        <v-btn v-if="false" class="ml-3" style="vertical-align: middle;" rounded color="primary" small
               @click="showStartBalanceDialog()"
        >
          <v-icon left>mdi-store</v-icon> Формирование остатков продукта
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="nonSortableHeaders"
      :items="productCollections"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных"
      loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td class="text-center">{{item.id|prettyInt}}</td>
          <td>{{item.reg_date|date}}</td>
          <td>{{item.total_price|money}}</td>
          <td class="text-right">
            <v-btn class="ml-2" text small depressed color="success" @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon>
              Показать
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-model="detailDialog" width="60vw" persistent>
      <product-details @dialog:close="closeDetailsDialog"></product-details>
    </v-dialog>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('ProductCollection')

  import ProductDetails from '@/modules/product-collection/details'
  import ProductForm from '@/modules/product-collection/form'

  import {hasFilter, hasDialog} from '@/mixins'

  export default {
    name: 'product-collection-list',

    mixins: [hasFilter, hasDialog],

    data() {
      return {
        startingPoint: false,
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
        'deleting',
      ]),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Рег номер документа', align: 'center', width: '200'},
          {text: 'Дата', value: 'reg_date'},
          {text: 'Стоимость'},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      productCollections() {
        return this.data?.results || []
      },
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel', 'setCollectionItems', 'createCollectionItem']),

      closeDetailsDialog() {
        this.setModel({})
        this.setCollectionItems([])
        this.detailDialog = false
      },

      showForm(item) {
        if (item) {
          this.show(item.id)
        } else {
          this.setModel({})
          this.createCollectionItem({})
        }
        this.formDialog = true
      },

      closeFormDialog() {
        this.formDialog = false
        this.startingPoint = false
        this.setModel({})
        this.setCollectionItems([])
        this.fetch(this.filter)
      },

      showStartBalanceDialog() {
        this.startingPoint = true
        this.showForm()
      },
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Список документов продукта')
      this.$store.dispatch('Product/fetch')
      this.$store.dispatch('Warehouse/fetch')
    },

    components: {
      ProductForm,
      ProductDetails,
    }
  }
</script>
