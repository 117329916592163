var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',{attrs:{"loading":!!_vm.fetching}},[_c('v-card-title',[_vm._v(_vm._s(_vm.model.id ? 'Изменить документ продукта' : 'Создать документ продукта'))]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"transferDate",attrs:{"disabled":_vm.regDateIsBlocked,"close-on-content-click":false,"max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm._f("date")(_vm.model.reg_date),"label":"Дата","prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.regDateIsBlocked}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.regDateMenu),callback:function ($$v) {_vm.regDateMenu=$$v},expression:"regDateMenu"}},[_c('v-date-picker',{attrs:{"disabled":_vm.regDateIsBlocked,"allowed-dates":_vm.allowedRegDates},on:{"input":function($event){_vm.regDateMenu = false}},model:{value:(_vm.model.reg_date),callback:function ($$v) {_vm.$set(_vm.model, "reg_date", $$v)},expression:"model.reg_date"}})],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"recipe_doc","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.materialWarehouses,"item-value":"id","item-text":"name","required":"","error-messages":errors,"label":"Склад (сырье)"},model:{value:(_vm.model.from_warehouse),callback:function ($$v) {_vm.$set(_vm.model, "from_warehouse", $$v)},expression:"model.from_warehouse"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"recipe_doc","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.productWarehouses,"item-value":"id","item-text":"name","required":"","error-messages":errors,"label":"Склад (продукт)"},model:{value:(_vm.model.warehouse),callback:function ($$v) {_vm.$set(_vm.model, "warehouse", $$v)},expression:"model.warehouse"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"description","rules":"max:1024"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"3","error-messages":errors,"label":"Описание"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"my-0"},[_vm._v("Типы продуктов")])]),_vm._l((_vm.collectionItems),function(item,index){return [_c('v-col',{attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"name":("products." + index),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.productTypes,"item-text":"name","item-value":"id","required":"","error-messages":errors,"label":item.id ? 'Продукт' : 'Тип продукта',"dense":"","disabled":!!item.id},on:{"change":function($event){item.id = null}},model:{value:(item.product_type),callback:function ($$v) {_vm.$set(item, "product_type", $$v)},expression:"item.product_type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":("amount." + index),"rules":"required|integer|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Кол-во","dense":"","type":"number","append-outer-icon":_vm.collectionItems.length > 1 ? 'mdi-close' : ''},on:{"click:append-outer":function($event){_vm.collectionItems.length > 1 ? _vm.removeCollectionItem(index) : function (){}}},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}}],null,true)})],1)]}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.createCollectionItem}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Добавить продукт")],1)],1),(_vm.deletingItems.length)?_c('v-col',{staticClass:"error--text",attrs:{"cols":"12"}},[_c('div',[_vm._v("Удаляемые записи: "+_vm._s(_vm.deletingItems.length))]),_vm._l((_vm.deletingItems),function(deletingItem){return _c('div',{key:deletingItem.id},[_vm._v(" "+_vm._s(deletingItem.product_type_name)+" - кол-во: "+_vm._s(deletingItem.amount)+" ")])})],2):_vm._e()],2)],1),_c('v-card-actions',{staticClass:"justify-space-between pb-4 px-4"},[_c('v-btn',{attrs:{"loading":!!_vm.saving,"disabled":!!_vm.saving || invalid,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.model.id ? 'Сохранить' : 'Создать'))],1),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Отмена")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }